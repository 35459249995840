import Cookies from "js-cookie";
import axios from "axios";
import qs from "qs";
import useLogOut from "./useLogOut";

const API = process.env.REACT_APP_API;

const useRefresh = () => {
  const isUseLogOut = useLogOut();

  const getRefreshToken = () => {
    let refreshToken = Cookies.get("refreshToken");
    axios
      .post(
        `${API}/refresh-token`,
        qs.stringify({
          token: refreshToken,
        })
      )
      .then((form) => {
        const time = 12 / 24;
        Cookies.set("name", form.data.name, { expires: time });
        Cookies.set("refreshToken", form.data.tokens.refresh_token.jwt, {
          expires: time,
        });
        Cookies.set("accessToken", form.data.tokens.access_token.jwt, {
          expires: time,
        });
      })
      .catch((error) => {
        isUseLogOut.getLogOut();
      });
  };

  return { getRefreshToken };
};

export default useRefresh;
