import React, { useState } from "react";
import axios from "axios";
import Upload from "rc-upload";
import Cookies from "js-cookie";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const AddPhoto = ({ setPhoto_id, setPhotos_id, multiple }) => {
  const [info, setInfo] = useState("");
  const [info2, setInfo2] = useState("");
  const [percent, setPercent] = useState(0);
  const [color, setColor] = useState("black");

  const API = process.env.REACT_APP_API;
  let accessToken = Cookies.get("accessToken");

  const uploadProps = {
    action: `${API}/photo/add`,
    name: "photo",
    multiple: false,
    data: { a: 1, b: 2 },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    beforeUpload(file) {
      console.log(file.type);
      console.log(accessToken);
      setInfo(" ");
      setInfo2(" ");
      setPercent(0);
      setColor("black");
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/gif"
      ) {
        setInfo("Niewłaściwy format pliku.");
        setInfo2("Spróbuje ponownie.");
        setColor("red");
        return false;
      }
    },
    onStart(file) {
      console.log(accessToken);
      console.log("onStart", file, file.name);
    },
    onSuccess(res, file) {
      // console.log("onSuccess", res, file.name);
      if (res.error === undefined) {
        setInfo("Zdjęcie zostało dodane!");
        setInfo2("Dodaj kolejny obraz lub wróć do poprzedniego widoku.");
        setColor("black");

        if (multiple === false) {
          setPhoto_id(res.id);
        } else {
          // setPhotos_id((prevState) => [...prevState, res.id]);
          setPhotos_id(res.id);
        }

        // console.log(res);
      } else {
        setInfo("Błąd dodawania pliku!");
        setInfo2("Spróbuje ponownie.");
        setColor("red");
      }
    },
    onError(err) {
      // console.log("onError", err);
    },
    onProgress({ percent }, file) {
      // console.log("onProgress", `${percent}%`, file.name);
    },
    customRequest({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
    }) {
      // EXAMPLE: post form-data with 'axios'
      // eslint-disable-next-line no-undef
      const formData = new FormData();
      if (data) {
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
      }
      formData.append(filename, file);
      axios
        .post(action, formData, {
          withCredentials,
          headers,
          onUploadProgress: ({ total, loaded }) => {
            onProgress(
              { percent: Math.round((loaded / total) * 100).toFixed(2) },
              file,
              setPercent(Math.round((loaded / total) * 100))
            );
          },
        })
        .then(({ data: response }) => {
          onSuccess(response, file);
        })
        .catch(onError);
      return {
        abort() {
          // console.log("upload progress is aborted.");
        },
      };
    },
  };

  return (
    <>
      <div className="addPhoto buttonBox sBtn">
        <div className="addPhotoInfo">
          Nadaj nazwy zdjęciom!
        </div>
        <Upload {...uploadProps}>
          <Button
            variant="contained"
            size="large"
            endIcon={<CloudUploadIcon />}
          >
            Wybierz zdjęcie!
          </Button>
        </Upload>
        <div style={{ color: color }}>
          <p>{info}</p>
          <p>{info2}</p>
        </div>
        <div className="progressBarWithPercent">
          <div className="progressBar">
            <div style={{ width: percent + "%" }}></div>
          </div>
          <p> {percent}&nbsp;%</p>
        </div>
      </div>
    </>
  );
};

export { AddPhoto };
