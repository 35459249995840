import React from "react";

const NameDisabled = ({ number, handleChangeInput }) => {
  return (
    <div className="input">
      <div className="row">
        <div className="form-part input inputText">
          <label>
            Nazwa nr {number}
            <input
              type="text"
              className="validate"
              name={number}
              minLength="1"
              style={{
                  backgroundColor: "#cbcdce",
                  borderBottom: "1px solid",
                  marginTop: "5px",
              }}
              disabled={true}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export { NameDisabled };
