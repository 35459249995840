import React, { useState, useEffect } from "react";
import { TopBar } from "./TopBar";
import { Footer } from "./Footer";
import { Loader } from "./Loader";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import useRefresh from "./useRefresh";
import ForumIcon from "@material-ui/icons/Forum";
import AddCommentOutlinedIcon from "@material-ui/icons/AddCommentOutlined";
import Alert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";
import Filter5Icon from "@material-ui/icons/Filter5";
import { DebounceInput } from "react-debounce-input";

const ProductsList = () => {
  const [form, setForm] = useState([]);
  const [loader, setLoader] = useState("loader");
  const [refreshList, setRefreshList] = useState(4);
  const [q, setQ] = useState("");

  const API = process.env.REACT_APP_API;

  let accessToken = Cookies.get("accessToken");
  const isUseRefresh = useRefresh();

  localStorage.setItem("feature_group_id", null);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let wait = (Math.floor(Math.random() * (5 - 1 + 1)) + 1) * 1000;
    refreshList > 0 && refreshList < 4 && setTimeout(getProducts(), wait);
    // eslint-disable-next-line
  }, [refreshList]);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const search = (data) => {
    let singleWords = q.toLowerCase().split(" ");
    return data.filter((data) =>
      singleWords
        .filter((singleWords) => singleWords.length > 2)
        .every(
          (item) =>
            data.name.toLowerCase().includes(item) ||
            data.code.toLowerCase().includes(item) ||
            data.id.toLowerCase().includes(item)
        )
    );
  };

  const getProducts = () => {
    axios
      .get(`${API}/products`, config)
      .then((response) => {
        setForm(response.data);
        setLoader("list");
        setRefreshList(0);
      })
      .catch((error) => {
        error.message === "invalid refresh token"
          ? isUseRefresh.getRefreshToken()
          : setRefreshList((prevState) => prevState - 1);
        refreshList === 1 && setLoader("alert");
      });
  };

  return (
    <>
      <TopBar />
      <div className="productsList">
        {loader === "loader" && <Loader />}
        {loader === "alert" && (
          <Alert variant="filled" severity="error">
            This is an error alert !
          </Alert>
        )}
        {loader === "list" && (
          <>
            <div className="divWithTable">
              <div className="divWithHeading">
                <h2 className="card-title">Katalog produktów</h2>
                {/* <Button
                  className="saveBtn"
                  // onClick={() => submit()}
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  Dodaj nowy produkt
                </Button> */}
                <div></div>
              </div>
              <div className="searchBox">
                <SearchIcon />
                <DebounceInput
                  placeholder="Szukaj..."
                  type="text"
                  debounceTimeout={700}
                  minLength={3}
                  onChange={(e) => setQ(e.target.value)}
                />
              </div>
              {/* <MDBDataTable striped bordered small data={form} /> */}
              <table className="productsListTab">
                {/* <InstantSearch
                indexName="instant_search"
                // searchClient={searchClient}
              >
                Widgets
              </InstantSearch> */}

                <thead>
                  <tr>
                    <th>PID</th>
                    <th>Zdjęcie</th>
                    <th>Nazwa produktu</th>
                    <th>Kod</th>
                    <th> </th>
                    <th> </th>
                    <th>Nazwy </th>
                  </tr>
                </thead>
                <tbody>
                  {form[0] !== undefined &&
                    search(form)
                      .sort((a, b) => b.id - a.id)
                      .map((e) => (
                        <tr key={e.id}>
                          <td>{e.id}</td>
                          <td>
                            {e.thumbnail !== null && (
                              <LazyLoadComponent>
                                <div
                                  className="patientListImg"
                                  style={{
                                    backgroundImage: `url(${e.thumbnail.thumbnail.photo_url})`,
                                  }}
                                ></div>
                              </LazyLoadComponent>
                            )}
                          </td>
                          <td>{e.name}</td>
                          <td>{e.code}</td>
                          <td
                            className="chooseProductIcon"
                            onClick={() =>
                              localStorage.setItem("mode", "empty")
                            }
                          >
                            <NavLink to={`product/${e.id}`}>
                              <AddCommentOutlinedIcon />
                            </NavLink>
                          </td>
                          <td
                            className="chooseProductIcon"
                            onClick={() => localStorage.setItem("mode", "all")}
                          >
                            <NavLink to={`product/${e.id}`}>
                              <ForumIcon />
                            </NavLink>
                          </td>
                          <td
                            className="chooseProductIcon"
                            onClick={() =>
                              localStorage.setItem("mode", "names")
                            }
                          >
                            <NavLink to={`product/${e.id}`}>
                              <Filter5Icon />
                            </NavLink>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export { ProductsList };
