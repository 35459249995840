import React, { useEffect, useState } from "react";

const Decimal = ({ feature, error, handleChangeInput }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (feature.value === null || feature.value === undefined) {
      setValue("");
    } else {
      setValue(feature.value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={
        error !== undefined && error !== "" && value === "" ? " emptyCell" : ""
      }
    >
      <div className="form-part input">
        <div>
          <label>
            {feature.name}
            <input
              type="number"
              onKeyDown={(e) =>
                (e.key === "e" && e.preventDefault()) ||
                (e.key === "," && e.key.replace(",", "."))
              }
              className="validate"
              step="any"
              min="0"
              minLength="1"
              name={feature.id}
              value={value}
              onChange={(e) => {
                handleChangeInput(e);
                // e.replace(/,/g, ".");
                setValue(e.target.value);
              }}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export { Decimal };
