import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import SearchIcon from "@material-ui/icons/Search";
import { DebounceInput } from "react-debounce-input";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Loader } from "./Loader";

const SelectProduct = ({ setProduct_id, closeModalProduct }) => {
  const [form, setForm] = useState([]);
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState("loader");
  const [refreshList, setRefreshList] = useState(4);
  const [q, setQ] = useState("");
  const [markPhoto, setMarkPhoto] = useState("");

  const API = process.env.REACT_APP_API;

  let accessToken = Cookies.get("accessToken");
  // const isUseRefresh = useRefresh();

  localStorage.setItem("feature_group_id", null);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // console.log(products.length);
    for (let i = 0; i < products.length; i++) {
      if (products[i].type.id === 1) {
        setForm((prevState) => {
          return [...prevState, products[i]];
        });
      }
    }
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    let wait = (Math.floor(Math.random() * (5 - 1 + 1)) + 1) * 1000;
    refreshList > 0 && refreshList < 4 && setTimeout(getProducts(), wait);
    // eslint-disable-next-line
  }, [refreshList]);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const search = (data) => {
    let singleWords = q.toLowerCase().split(" ");
    return data.filter((data) =>
      singleWords
        .filter((singleWords) => singleWords.length >= 1)
        .every(
          (item) =>
            data.name.toLowerCase().includes(item) ||
            data.code.toLowerCase().includes(item) ||
            data.id.toLowerCase().includes(item)
        )
    );
  };

  const getProducts = () => {
    setForm([]);
    setLoader("loader");

    axios
      .get(`${API}/products`, config)
      .then((response) => {
        // console.log(response.data);
        setProducts(response.data);
        setLoader("list");
        setRefreshList(0);
      })
      .catch((error) => {
        // error.message === "invalid refresh token"
        //   ? isUseRefresh.getRefreshToken()
        //   : setRefreshList((prevState) => prevState - 1);
        // refreshList === 1 && setLoader("alert");
      });
  };

  const select = (e) => {
    if (markPhoto !== e.id) {
      setMarkPhoto(e.id);
    } else {
      setMarkPhoto(" ");
    }
  };

  const submit = (e) => {
    setProduct_id(markPhoto);
    closeModalProduct();
  };

  // console.log(form);

  return (
    <>
      <div className="divSelectPhoto">
        <div className="searchBox">
          <SearchIcon />
          <DebounceInput
            placeholder="Szukaj..."
            type="text"
            debounceTimeout={700}
            minLength={1}
            onChange={(e) => setQ(e.target.value)}
          />
        </div>
        <div className="productsListTab selectPhotoList">
          {loader === "loader" && <Loader />}
          {loader === "list" && (
              products !== undefined &&
              search(products)
                .sort((a, b) => b.id - a.id)
                .map((e) => (
                  <div key={e.id}>
                    {e.thumbnail !== null ? (
                      <LazyLoadComponent>
                        <div
                          className={
                            markPhoto === e.id ? "markDiv allPhotos" : "allPhotos"
                          }
                        >
                          <img
                            title={"PID: "+e.id + "; "+e.name}
                            // alt={e.name}
                            // data-tip={e.name}
                            onClick={() => select(e)}
                            className="productListImg"
                            src={e.thumbnail.thumbnail.photo_url}
                          ></img>
                        </div>
                      </LazyLoadComponent>
                    ) : (
                      <div
                        title={"PID: "+e.id + "; "+e.name}
                        className={
                          markPhoto === e.id
                            ? "markDiv selectPhotoSpin"
                            : "selectPhotoSpin"
                        }
                        onClick={() => select(e)}
                      >
                        PID: {e.id}; {e.name}
                      </div>
                    )}
                  </div>
                )))}
        </div>
        <div className="buttonBox">
          <Button
            className="saveBtn"
            onClick={() => closeModalProduct()}
            variant="contained"
            size="large"
            color="primary"
            startIcon={<NavigateBeforeIcon />}
          >
            Powrót
          </Button>

          <Button
            className="saveBtn"
            onClick={() => submit()}
            variant="contained"
            size="large"
            color="primary"
            startIcon={<SaveIcon />}
          >
            Zapisz
          </Button>
        </div>
      </div>
    </>
  );
};

export { SelectProduct };
