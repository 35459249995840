import React from "react";

const Name = ({ number, handleChangeInput }) => {
  return (
    <div className="input">
      <div className="row">
        <div className="form-part input inputText">
          <label>
            Nazwa nr {number}
            <input
              type="text"
              className="validate"
              name={number}
              minLength="1"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export { Name };
