import React, { useState, useEffect } from "react";
import { TopBar } from "./TopBar";
import { AddPhoto } from "./AddPhoto";
import { SelectPhoto } from "./SelectPhoto";
import Cookies from "js-cookie";
import axios from "axios";
import useRefresh from "./useRefresh";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { Editor } from "@tinymce/tinymce-react";
import SaveIcon from "@material-ui/icons/Save";
import qs from "qs";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {NavLink, useHistory} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

Modal.setAppElement("#root");

const AddZebra = () => {
  const [photo_id, setPhotoId] = useState("");
  const [description_id, setDescriptionId] = useState("");
  const [description_language, setDescriptionLanguage] = useState("");
  const [description_text, setDescriptionText] = useState("");
  const [description_header, setDescriptionHeader] = useState("");
  const [refreshList, setRefreshList] = useState(4);
  const [addPhoto, setAddPhoto] = useState(false);
  const [selectPhoto, setSelectPhoto] = useState(false);
  const [showSelectedPhoto, setShowSelectedPhoto] = useState(null);
  const [showPhotoLoader, setShowPhotoLoader] = useState("false");
  const [alert, setAlert] = useState("");

  const API = process.env.REACT_APP_API;

  const history = useHistory();

  let accessToken = Cookies.get("accessToken");
  const isUseRefresh = useRefresh();

  useEffect(() => {
    const end = () => {
      setAlert("");
    };

    let endAlert = setTimeout(end, 5000);
    // eslint-disable-next-line
  }, [alert]);

  useEffect(() => {
    let wait = (Math.floor(Math.random() * (5 - 1 + 1)) + 1) * 1000;
    refreshList > 0 && refreshList < 4 && setTimeout(getSelectedDescription(), wait);
    // eslint-disable-next-line
  }, [refreshList]);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const closeModalPhoto = () => {
    setAddPhoto(false);
    setSelectPhoto(false);
  };

  useEffect(() => {
    setShowSelectedPhoto(null);
    getSelectedPhoto();
    // eslint-disable-next-line
  }, [photo_id]);

  useEffect(() => {
      const timeoutId = setTimeout(() => getSelectedDescription(), 1000);
      return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [description_id]);

  const getSelectedPhoto = () => {
      if(photo_id=="")
      {
          setShowPhotoLoader(false);
          setShowSelectedPhoto(null);
      }
      else
      {
          axios
              .get(`${API}/photo/${photo_id}`, {
                  headers: {
                      Authorization: `Bearer ${accessToken}`,
                  },
              })
              .then((response) => {
                  if (response.data.sizes !== null) {
                      setShowPhotoLoader(false);
                      setShowSelectedPhoto(response.data);
                  } else {
                      setShowPhotoLoader(true);
                      setTimeout(() => {
                          getSelectedPhoto();
                      }, 60000);
                  }
              })
              .catch((error) => {});
      }
  };

  const getSelectedDescription = () => {
    if(description_id=="")
    {
        setDescriptionLanguage("");
        setDescriptionHeader("");
        setDescriptionText("<p></p>");
    }
    else
    {
        axios
            .get(`${API}/description/${description_id}`, config)
            .then((response) => {
                if(response.data.id===undefined)
                {
                    setDescriptionId("");
                    setDescriptionLanguage("");
                    setDescriptionHeader("");
                    setDescriptionText("<p></p>");
                }
                else
                {
                    if(response.data.language===null)
                    {
                        setDescriptionLanguage("");
                    }
                    else
                    {
                        setDescriptionLanguage(response.data.language.name);
                    }

                    if(response.data.header===null)
                    {
                        setDescriptionHeader("");
                    }
                    else
                    {
                        setDescriptionHeader(response.data.header);
                    }

                    if(response.data.text===null)
                    {
                        setDescriptionText("<p></p>");
                    }
                    else
                    {
                        setDescriptionText(response.data.text);
                    }
                }
            })
            .catch((error) => {
                if(error.response.status=="200")
                {
                    error.message === "invalid refresh token"
                        ? isUseRefresh.getRefreshToken()
                        : setRefreshList((prevState) => prevState - 1);
                }
                else
                {
                    setDescriptionId("");
                    setDescriptionLanguage("");
                    setDescriptionHeader("");
                    setDescriptionText("<p></p>");
                }
            });
    }
  }

  const submit = () => {
    if (
      photo_id.toString().length > 0 &&
      description_id.toString().length > 0
    ) {
      axios
        .post(
          `${API}/zebra/put`,
          qs.stringify({ photo_id, description_id }),
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((form) => {
          if (form.data.id !== undefined) {
            setDescriptionLanguage("");
            setPhotoId("");
            setDescriptionText("");
            setDescriptionHeader("");
            setShowSelectedPhoto(null);
            setDescriptionId("");
          }

          let time = new Date();
          time.setTime(time.getTime() + 15 * 1000);
          Cookies.set("alert", "newZebra", { expires: time });

          window.location.href = `#/editZebra/${form.data.id}`;
        })
        .catch((error) => {});
    } else {
      window.scrollTo(0, 0);
      setAlert("noData");
    }
  };

  return (
    <>
      <TopBar />
      <div className="alert">
        {alert === "noData" && (
          <Alert variant="filled" severity="Success">
            Uzupełnij wszystkie pola!
          </Alert>
        )}
      </div>
      <div className="divWithTable divAddZebra">
        <div className="divWithHeading">
          <div className="onlyZebra">
            <h2 className="card-title">Dodaj zebrę</h2>
            <div className="addZebra">
              <div className="addPresentationPart1">
                <div className="addZebraPart1">
                  <h3>1. Wybierz zdjęcie</h3>
                  <Modal
                    isOpen={addPhoto}
                    onRequestClose={() => closeModalPhoto()}
                    className="ReactModalPortal"
                    overlayClassName="ReactModal__Overlay"
                  >
                    <div className="insideModal">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => closeModalPhoto()}
                      />
                      <AddPhoto setPhoto_id={setPhotoId} multiple={false} />
                    </div>
                  </Modal>

                  <Button
                    className="saveBtn"
                    onClick={() => setAddPhoto(true)}
                    variant="contained"
                    size="large"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    Dodaj nowe zdjęcie
                  </Button>

                  <Modal
                    isOpen={selectPhoto}
                    onRequestClose={() => closeModalPhoto()}
                    className="ReactModalPortal"
                    overlayClassName="ReactModal__Overlay"
                  >
                    <div className="insideModal">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => closeModalPhoto()}
                      />
                      <SelectPhoto
                        setPhoto_id={setPhotoId}
                        multiple={false}
                        closeModalPhoto={() => closeModalPhoto()}
                      />
                    </div>
                  </Modal>

                  <Button
                    className="saveBtn"
                    onClick={() => setSelectPhoto(true)}
                    variant="contained"
                    size="large"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    Wybierz zdjęcie z katalogu
                  </Button>
                  <div className="showSelectedPhoto addZebraLoader">
                    {showPhotoLoader === true && (
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    )}
                    {showSelectedPhoto !== null && (
                      <img alt="selected_photo" src={showSelectedPhoto.thumbnail.photo_url} title={"PHLID: " + showSelectedPhoto.id}></img>
                    )}
                  </div>
                </div>
                <div>
                  <h3>2. Wybierz opis</h3>

                    <NavLink to="/addDescription" target="_blank">
                        <Button
                            className="saveBtn"
                            variant="contained"
                            size="large"
                            color="primary"
                            startIcon={<AddIcon />}
                        >
                            Dodaj nowy opis
                        </Button>
                    </NavLink>

                    <h3>Przypisz tekst o PDEID</h3>

                    <input
                      value={description_id}
                      type="text"
                      className="validate "
                      minLength="1"
                      onChange={(e) => {
                        setDescriptionId(e.target.value);
                      }}
                    />

                  <h3>Język</h3>
                    <input
                        value={description_language}
                        type="text"
                        className="readonly"
                    />

                  <h3>Nagłówek</h3>
                  <input
                    value={description_header}
                    type="text"
                    className="readonly"
                  />
                  <h3>Tekst</h3>
                  <Editor
                    value={description_text}
                    disabled={true}
                    className="readonly"
                    init={{
                      height: 300,
                      width: 700,
                      menubar: false,
                      entity_encoding: "raw",

                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help",
                      content_style: 'body { background-color: #d3d3d3; }'
                    }}
                  />
                </div>
              </div>
              <div className="buttonBox">
                <Button
                  className="saveBtn"
                  onClick={() => {
                    history.push("/zebraCatalog");
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<NavigateBeforeIcon />}
                >
                  Powrót
                </Button>

                <Button
                  className="saveBtn"
                  onClick={() => submit()}
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                >
                  Zapisz
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AddZebra };
