import React, { useEffect, useState } from "react";

const Int = ({ feature, error, handleChangeInput }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (feature.value === null || feature.value === undefined) {
      setValue("");
    } else {
      setValue(feature.value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={
        error !== undefined && error !== "" && value === "" ? "emptyCell" : ""
      }
    >
      <div className="form-part input">
        <div>
          <label>
            {feature.name}
            <input
              type="number"
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "." || evt.key === ",") &&
                evt.preventDefault()
              }
              className="validate"
              step="any"
              minLength="1"
              name={feature.id}
              value={value}
              onChange={(e) => {
                handleChangeInput(e);
                setValue(e.target.value);
              }}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export { Int };
