import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SearchIcon from "@material-ui/icons/Search";
import { DebounceInput } from "react-debounce-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "./Loader";

const SelectPhoto = ({
  setPhoto_id,
  setPhotos_id,
  multiple,
  closeModalPhoto,
}) => {
  const [form, setForm] = useState([]);
  const [loader, setLoader] = useState("");
  const [refreshList, setRefreshList] = useState(4);
  const [q, setQ] = useState("");
  const [markPhoto, setMarkPhoto] = useState("");

  const API = process.env.REACT_APP_API;

  let accessToken = Cookies.get("accessToken");
  // const isUseRefresh = useRefresh();

  localStorage.setItem("feature_group_id", null);

  useEffect(() => {
    let wait = (Math.floor(Math.random() * (5 - 1 + 1)) + 1) * 1000;
    refreshList > 0 && refreshList < 4 && setTimeout(getPhotos(2), wait);
    // eslint-disable-next-line
  }, [refreshList]);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const search = (data) => {
    let singleWords = q.toLowerCase().split(" ");
    return data.filter((data) =>
      singleWords
        .filter((singleWords) => singleWords.length >= 1)
        .every(
          (item) =>
            data.id.toString().toLowerCase().includes(item) ||
            (data.products !== null &&
                Object.values(data.products).some((e) => e.some((e1) => e1.name.toLowerCase().includes(item)))
            )
        )
    );
  };

  const getPhotos = (type) => {
    let url = type==1 ? `${API}/photos?type=all` : `${API}/photos?type=unassigned`;

    setForm([]);
    setLoader("loader");

    axios
      .get(url, config)
      .then((response) => {
        // console.log(response.data);
        setForm(response.data);
        setLoader("list");
        setRefreshList(0);
      })
      .catch((error) => {});
  };

  const select = (e) => {
    if (markPhoto !== e.id) {
      setMarkPhoto(e.id);
    } else {
      setMarkPhoto(" ");
    }
  };

  const submit = () => {
    if (multiple === false) {
      setPhoto_id(markPhoto);
    } else {
      setPhotos_id(markPhoto);
    }
    closeModalPhoto();
  };

  return (
    <>
      <div className="divSelectPhoto">
        <div className="searchBox">
          <SearchIcon />
          <DebounceInput
            placeholder="Szukaj..."
            type="text"
            debounceTimeout={700}
            minLength={1}
            onChange={(e) => setQ(e.target.value)}
            onKeyDown={(e) => {
              if(e.keyCode==13 && e.target.value.length>0)
              {
                setQ(e.target.value)
                getPhotos(1);
              }
            }}
          />
        </div>
        <div style={{marginBottom: "20px"}}>
          <Button
              onClick={() => getPhotos(2)}
              variant="contained"
              size="large"
              color="primary"
          >
            Pokaż nieprzypisane
          </Button>
          &nbsp;
          <Button
              onClick={() => getPhotos(1)}
              variant="contained"
              size="large"
              color="primary"
          >
            Pokaż wszystkie
          </Button>
        </div>
        <div className="productsListTab selectPhotoList">
          {loader === "loader" && <Loader />}
          {loader === "list" && (
            form[0] !== undefined &&
              search(form)
                .slice(0)
                .reverse()
                .map((e) => (
                  <div key={e.id}>
                    {e.sizes !== null ? (
                      <LazyLoadComponent>
                        <div
                          className={
                            markPhoto === e.id ? "markDiv allPhotos" : "allPhotos"
                          }
                        >
                          <img
                            title={"PHLID: " + e.id}
                            onClick={() => select(e)}
                            className="productListImg"
                            src={e.thumbnail.photo_url}
                          ></img>
                        </div>
                      </LazyLoadComponent>
                    ) : (
                      <div
                        className={
                          markPhoto === e.id
                            ? "markDiv selectPhotoSpin"
                            : "selectPhotoSpin"
                        }
                        onClick={() => select(e)}
                      >
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                      </div>
                    )}
                  </div>
                )))}
        </div>
        <div className="buttonBox">
          <Button
            className="saveBtn"
            onClick={() => closeModalPhoto()}
            variant="contained"
            size="large"
            color="primary"
            startIcon={<NavigateBeforeIcon />}
          >
            Powrót
          </Button>

          <Button
            className="saveBtn"
            onClick={() => submit()}
            variant="contained"
            size="large"
            color="primary"
            startIcon={<SaveIcon />}
          >
            Zapisz
          </Button>
        </div>
      </div>
    </>
  );
};

export { SelectPhoto };
