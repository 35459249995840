import React, { useState, useEffect } from "react";

const Switch = ({ feature, handleChangeSwitch, empty, mode }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (feature.value == 0) {
      setValue(false);
    } else if (feature.value == 1) {
      setValue(true);
    } else if (feature.value === null) {
      setValue("");
    } else {
      setValue(feature.value);
    }
    // eslint-disable-next-line
  }, []);

  const name = feature.id;

  const onChange = (e, value, name) => {
    setValue((prevState) => !prevState);
    handleChangeSwitch(e, !value, name);
  };

  return (
    <>
      <div className={empty === true && value === "" ? "emptyCell" : " "}>
        <div
          className={
            value === true || value === false ? "switch" : "switch noAnswer"
          }
        >
          <p>{feature.name}</p>
          <label>
            Nie
            <input
              type="checkbox"
              name={feature.id}
              onChange={(e) => {
                onChange(e, value, name);
              }}
              checked={value}
            />
            <span className="lever"></span>
            Tak
          </label>
        </div>
      </div>
    </>
  );
};

export { Switch };
