import React, { useState, useEffect } from "react";
import { TopBar } from "./TopBar";
import { Footer } from "./Footer";
import { Loader } from "./Loader";
import { AddPhoto } from "./AddPhoto";
import Cookies from "js-cookie";
import axios from "axios";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import useRefresh from "./useRefresh";
import Alert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { DebounceInput } from "react-debounce-input";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import {NavLink} from "react-router-dom";
import {ReplacePhoto} from "./ReplacePhoto";
import {QuestionModal} from "./QuestionModal";

const PhotoCatalog = () => {
  const [form, setForm] = useState([]);
  const [loader, setLoader] = useState("loader");
  const [refreshList, setRefreshList] = useState(4);
  const [q, setQ] = useState("");
  const [addPhoto, setAddPhoto] = useState(false);
  const [replacePhoto, setReplacePhoto] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [alert, setAlert] = useState("");

  const API = process.env.REACT_APP_API;

  let accessToken = Cookies.get("accessToken");
  const isUseRefresh = useRefresh();

  localStorage.setItem("feature_group_id", null);

  useEffect(() => {
    getPhotos();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let wait = (Math.floor(Math.random() * (5 - 1 + 1)) + 1) * 1000;
    refreshList > 0 && refreshList < 4 && setTimeout(getPhotos(), wait);
    // eslint-disable-next-line
  }, [refreshList]);

  useEffect(() => {
    const end = () => {
      setAlert("");
    };

    let endAlert = setTimeout(end, 5000);
    // eslint-disable-next-line
  }, [alert]);

  useEffect(() => {
    setAlert(Cookies.get("alert"));

    const end = () => {
      let time = 4 / 24;
      Cookies.set("alert", "", { expires: time });
      setAlert("");
    };

    let endAlert = setTimeout(end, 5000);

    // eslint-disable-next-line
  }, []);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const search = (data) => {
    let singleWords = q.toLowerCase().split(" ");
    return data.filter((data) =>
      singleWords
        .filter((singleWords) => singleWords.length > 1)
        .every(
          (item) =>
            data.id.toLowerCase().includes(item) ||
            (data.products !== null &&
                Object.values(data.products).some((e) => e.some((e1) => e1.name.toLowerCase().includes(item)))
            )
        )
    );
  };

  const getPhotos = () => {
    axios
      .get(`${API}/photos?type=all`, config)
      .then((response) => {
        // console.log(response.data);
        setForm(response.data);
        setLoader("list");
        setRefreshList(0);
      })
      .catch((error) => {
        error.message === "invalid refresh token"
          ? isUseRefresh.getRefreshToken()
          : setRefreshList((prevState) => prevState - 1);
        refreshList === 1 && setLoader("alert");
      });
  };

  const closeModal = () => {
    setAddPhoto(false);
    setReplacePhoto(null);
    getPhotos();
  };

  const closeDeleteModal = (response) => {
    if(response==null)
    {
      setDeleteModal(null);
      getPhotos();
    }
    else
    {
      setDeleteModal(null);

      let time = new Date();
      time.setTime(time.getTime() + 15 * 1000);
      Cookies.set("alert", "deletePhoto", { expires: time });
      setAlert("deletePhoto");

      window.scrollTo(0, 0);
      getPhotos();
    }
  };

  const photoProductConnectionType = (connectionType) => {
    switch(connectionType) {
      case "1":
        return 'PREZENTACJA - MINIATURA';
      case "2":
        return 'PREZENTACJA - GALERIA';
      case "3":
        return 'ZEBRA';
      default:
        return '';
    }
  }

  return (
    <>
      <TopBar />
      <div className="alert">
        {alert === "deletePhoto" && (
            <Alert variant="filled" severity="Success">
              Zdjęcie zostało usunięte!
            </Alert>
        )}
      </div>
      <div className="productsList">
        {loader === "loader" && <Loader />}
        {loader === "alert" && (
          <Alert variant="filled" severity="error">
            This is an error alert !
          </Alert>
        )}
        {loader === "list" && (
          <>
            <div className="divWithTable">
              <div className="divWithHeading">
                <h2 className="card-title">Katalog zdjęć</h2>
                <Modal
                  isOpen={addPhoto}
                  onRequestClose={() => closeModal()}
                  className="ReactModalPortal"
                  overlayClassName="ReactModal__Overlay"

                  useNativeDriver={true}
                  useNativeDriverForBackdrop={true}
                >
                  <div className="insideModal">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => closeModal()}
                    />
                    <AddPhoto />
                  </div>
                </Modal>
                <Modal
                    isOpen={replacePhoto}
                    onRequestClose={() => closeModal()}
                    className="ReactModalPortal"
                    overlayClassName="ReactModal__Overlay"

                    useNativeDriver={true}
                    useNativeDriverForBackdrop={true}
                >
                  <div className="insideModal">
                    <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => closeModal()}
                    />
                    <ReplacePhoto setPhoto_id={replacePhoto} />
                  </div>
                </Modal>
                <Modal
                    isOpen={deleteModal}
                    onRequestClose={() => closeDeleteModal(null)}
                    className="ReactModalPortal"
                    overlayClassName="ReactModal__Overlay"

                    useNativeDriver={true}
                    useNativeDriverForBackdrop={true}
                >
                  <div className="insideModal">
                    <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => closeDeleteModal(null)}
                    />
                    <QuestionModal question="Czy chcesz usunąć wybrane zdjęcie?" closeDeleteModal={closeDeleteModal} url={`photo/${deleteModal}/delete`} />
                  </div>
                </Modal>
                <Button
                  className="saveBtn"
                  onClick={() => setAddPhoto(true)}
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  Dodaj nowe zdjęcie
                </Button>
              </div>
              <div className="searchBox">
                <SearchIcon />
                <DebounceInput
                  placeholder="Szukaj..."
                  type="text"
                  debounceTimeout={700}
                  minLength={3}
                  onChange={(e) => setQ(e.target.value)}
                />
              </div>
              <div className="table-list">
                <table>
                  <thead>
                    <tr>
                      <th>PHLID</th>
                      <th>Zdjęcie</th>
                      <th>Przypisane produkty</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {form[0] !== undefined &&
                      search(form)
                        .slice(0)
                        .reverse()
                        .map((e) => (
                          <tr key={e.id}>
                            <td>{e.id}</td>
                            <td>
                              {e.thumbnail !== null ? (
                                <LazyLoadComponent>
                                  <div className="divWithMiniLoader">
                                    <img
                                      alt="photo_catalog"
                                      className="patientListImg"
                                      src={e.thumbnail.photo_url}
                                    ></img>
                                  </div>
                                </LazyLoadComponent>
                              ) : (
                                <div className="divWithMiniLoader">
                                  <div>
                                    <FontAwesomeIcon icon={faCircleNotch} spin />
                                  </div>
                                </div>
                              )}
                            </td>
                            <td>
                              {
                                e.products !== null &&
                                  Object.keys(e.products).map((connectionType) => {
                                    return (
                                        <p>
                                          <p className="text-bold">{photoProductConnectionType(connectionType)}</p>
                                          {e.products[connectionType].map((el) => {
                                          return (
                                              <p>- {el.name}</p>
                                          )})}
                                        </p>
                                    )
                                  })
                              }
                            </td>
                            <td>
                              <NavLink
                                  to="#"
                                  className="icon"
                                  onClick={() => setReplacePhoto(e.id)}
                              >
                                <EditIcon />
                              </NavLink>

                              {e.can_be_deleted ? (
                                  <NavLink
                                      to="#"
                                      className="icon"
                                      onClick={() => setDeleteModal(e.id)}
                                  >
                                    <DeleteIcon />
                                  </NavLink>
                              ) : ""}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer/>
    </>
  );
};

export { PhotoCatalog };
