import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SearchIcon from "@material-ui/icons/Search";
import { DebounceInput } from "react-debounce-input";
import {Loader} from "./Loader";

const SelectZebra = ({ setZebra_id, closeModalZebra }) => {
  const [form, setForm] = useState([]);
  const [loader, setLoader] = useState("loader");
  const [refreshList, setRefreshList] = useState(4);
  const [q, setQ] = useState("");
  const [markPhoto, setMarkPhoto] = useState("");

  const API = process.env.REACT_APP_API;

  let accessToken = Cookies.get("accessToken");
  // const isUseRefresh = useRefresh();

  localStorage.setItem("feature_group_id", null);

  useEffect(() => {
    getZebras();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let wait = (Math.floor(Math.random() * (5 - 1 + 1)) + 1) * 1000;
    refreshList > 0 && refreshList < 4 && setTimeout(getZebras(), wait);
    // eslint-disable-next-line
  }, [refreshList]);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const search = (data) => {
    // console.log(data);
    let singleWords = q.toLowerCase().split(" ");
    return data.filter((data) =>
      singleWords
        .filter((singleWords) => singleWords.length >= 1)
        .every(
          (item) =>
            data.id.toLowerCase().includes(item) ||
            (data.description!=null && data.description.header!=null && data.description.header.toLowerCase().includes(item)) ||
            data.description.language.name.toLowerCase().includes(item)
        )
    );
  };

  const getZebras = () => {
    setForm([]);
    setLoader("loader");

    axios
      .get(`${API}/zebras`, config)
      .then((response) => {
        // console.log(response.data);
        setForm(response.data);
        setLoader("list");
        setRefreshList(0);
      })
      .catch((error) => {});
  };

  const select = (e) => {
    if (markPhoto !== e.id) {
      setMarkPhoto(e.id);
    } else {
      setMarkPhoto(" ");
    }
  };

  const submit = () => {
    setZebra_id(markPhoto);
    closeModalZebra();
  };

  return (
    <>
      <div className="divSelectPhoto">
        <div className="searchBox">
          <SearchIcon />
          <DebounceInput
            placeholder="Szukaj..."
            type="text"
            debounceTimeout={700}
            minLength={1}
            onChange={(e) => setQ(e.target.value)}
          />
        </div>
        <div className="productsListTab selectPhotoList">
          {loader === "loader" && <Loader />}
          {loader === "list" && (
             form[0] !== undefined &&
              search(form)
                .slice(0)
                .reverse()
                .map((e) => (
                  <>
                    <div key={e.id}>
                      <LazyLoadComponent>
                        <div
                          title={"PZID: "+e.id}
                          className={
                            markPhoto === e.id ? "markDiv allPhotos" : "allPhotos"
                          }
                        >
                          <img
                            alt={e.id}
                            onClick={() => select(e)}
                            className="productListImg"
                            src={e.photo.thumbnail.photo_url}
                          ></img>
                        </div>
                      </LazyLoadComponent>
                    </div>
                  </>
              )))}
        </div>
        <div className="buttonBox">
          <Button
            className="saveBtn"
            onClick={() => closeModalZebra()}
            variant="contained"
            size="large"
            color="primary"
            startIcon={<NavigateBeforeIcon />}
          >
            Powrót
          </Button>

          <Button
            className="saveBtn"
            onClick={() => submit()}
            variant="contained"
            size="large"
            color="primary"
            startIcon={<SaveIcon />}
          >
            Zapisz
          </Button>
        </div>
      </div>
    </>
  );
};

export { SelectZebra };
