import React, { useEffect, useState } from "react";

const Radio = ({ featuresGroup, errorRadio, empty, setInputForm }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    featuresGroup.features.map((e) => {
      if (e.value === "1") {
        setValue(e.id);
        setInputForm(e.id);
      } else if (e.value == null || e.value === undefined) {
        setValue("0");
      }
      return value;
    });
    // eslint-disable-next-line
  }, []);

  const handleChangeRadio = (e, i) => {
    const { name } = e.target;
    setValue(name);
    setInputForm(name);
    // setValue(value);
  };

  return (
    <>
      <div>
        <p className="title">{featuresGroup.group.title}</p>
        <p className="title2">{featuresGroup.group.description}</p>
      </div>
      <div
        className={
          empty === true && errorRadio === true
            ? // && error.length > 0 && value.length === 0
              "radio emptyCell"
            : "radio"
        }
      >
        <div className="radioPart">
          {featuresGroup.features.map((e, i) => (
            <label key={i}>
              <input
                type="radio"
                name={e.id}
                value={e.value}
                onChange={(e, i, name) => handleChangeRadio(e, i, name)}
                checked={value == e.id ? true : false}
              />
              <div className="simplyRadio">
                <div
                  className="img"
                  style={{ backgroundImage: `url(images/ocean.jpg)` }}
                ></div>
                {e.name !== undefined && e.name.length > 0 && <p>{e.name}</p>}
              </div>
            </label>
          ))}
        </div>
      </div>
    </>
  );
};

export { Radio };
