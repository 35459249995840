import Cookies from "js-cookie";
import axios from "axios";
import qs from "qs";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const API = process.env.REACT_APP_API;

const useLogOut = () => {
  let history = useHistory();
  let accessToken = Cookies.get("accessToken");
  const [error, setError] = useState(false);

  const getLogOut = () => {
    axios
      .post(
        `${API}/signout`,
        qs.stringify({
          accessToken: accessToken,
        })
      )
      .then((form) => {
        Cookies.remove("name");
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        history.push({
          pathname: "/login",
        });
      })
      .catch((error) => {
        setError(true);
      });
  };

  return { getLogOut, error };
};

export default useLogOut;
