import React, { useEffect, useState } from "react";
import Slider from "@material-ui/core/Slider";

const Range = ({ feature, empty, handleChangeRange }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (feature.value === null || feature.value === undefined) {
      setValue("");
    } else {
      setValue(feature.value);
    }
    // eslint-disable-next-line
  }, []);

  const name = feature.id;

  const onChange = (e, value, name) => {
    handleChangeRange(e, value, name);
    setValue(value);
  };

  return (
    <div className={empty === true && value === "" ? "emptyCell" : ""}>
      <div
        className={
          value !== null && value.length !== 0
            ? "form-part range"
            : "form-part range noAnswer"
        }
      >
        <div>
          <p>{feature.name}</p>
          <Slider
            // defaultValue={0}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            name={feature.id.toString()}
            step={25}
            marks
            min={0}
            max={100}
            value={value}
            onChange={(e, value) => onChange(e, value, name)}
          />
        </div>
      </div>
    </div>
  );
};

export { Range };
