import React, { useState, useEffect } from "react";
import { TopBar } from "./TopBar";
import { Footer } from "./Footer";
import { Loader } from "./Loader";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import useRefresh from "./useRefresh";
import Alert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import { DebounceInput } from "react-debounce-input";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const PresentationCatalog = () => {
  const [form, setForm] = useState([]);
  const [loader, setLoader] = useState("loader");
  const [refreshList, setRefreshList] = useState(4);
  const [q, setQ] = useState("");
  const [alert, setAlert] = useState("");

  const API = process.env.REACT_APP_API;

  let accessToken = Cookies.get("accessToken");
  const isUseRefresh = useRefresh();

  useEffect(() => {
    window.scrollTo(0, 0);
    setAlert(Cookies.get("alert"));
    const end = () => {
      let time = 4 / 24;
      Cookies.set("alert", "", { expires: time });
      setAlert("");
    };

    let endAlert = setTimeout(end, 5000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPresentations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let wait = (Math.floor(Math.random() * (5 - 1 + 1)) + 1) * 1000;
    refreshList > 0 && refreshList < 4 && setTimeout(getPresentations(), wait);
    // eslint-disable-next-line
  }, [refreshList]);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const search = (data) => {
    let singleWords = q.toLowerCase().split(" ");
    return data.filter((data) =>
      singleWords
        .filter((singleWords) => singleWords.length > 1)
        .every(
          (item) =>
            data.id.toLowerCase().includes(item) ||
            data.product.name.toLowerCase().includes(item) ||
            data.language.name.toLowerCase().includes(item)
        )
    );
  };

  const getPresentations = () => {
    axios
      .get(`${API}/products-presentations`, config)
      .then((response) => {
        setForm(response.data.filter((presentation) => !presentation.product.archive));
        setLoader("list");
        setRefreshList(0);
      })
      .catch((error) => {
        error.message === "invalid refresh token"
          ? isUseRefresh.getRefreshToken()
          : setRefreshList((prevState) => prevState - 1);
        refreshList === 1 && setLoader("alert");
      });
  };

  return (
    <>
      <TopBar />
      <div className="alert">
        {alert === "newPresentation" && (
          <Alert variant="filled" severity="Success">
            Prezentacja produktu została dodana!
          </Alert>
        )}
        {alert === "editPresentation" && (
          <Alert variant="filled" severity="Success">
            Prezentacja produktu została edytowana!
          </Alert>
        )}
        {alert === "deletePresentation" && (
          <Alert variant="filled" severity="Success">
            Prezentacja produktu została usunięta!
          </Alert>
        )}
      </div>
      <div className="productsList">
        {loader === "loader" && <Loader />}
        {loader === "alert" && (
          <Alert variant="filled" severity="error">
            Błąd, spróbuj ponownie za chwilę!
          </Alert>
        )}
        {loader === "list" && (
          <>
            <div className="divWithTable">
              <div className="divWithHeading">
                <h2 className="card-title">Katalog prezentacji produktów</h2>
                <NavLink to="/addPresentation">
                  <Button
                    className="saveBtn"
                    // onClick={() => submit()}
                    variant="contained"
                    size="large"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    Dodaj nową prezentację
                  </Button>
                </NavLink>
              </div>
              <div className="searchBox">
                <SearchIcon />
                <DebounceInput
                  placeholder="Szukaj..."
                  type="text"
                  debounceTimeout={700}
                  minLength={3}
                  onChange={(e) => setQ(e.target.value)}
                />
              </div>
              <div className="table-list">
                <table>
                  <thead>
                    <tr>
                      <th>PPID</th>
                      <th>Miniatura</th>
                      <th>Produkt</th>
                      <th>Język</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {form[0] !== undefined &&
                      search(form)
                        .slice(0)
                        .reverse()
                        .map((e) => (
                          <tr key={e.id}>
                            <td>{e.id}</td>
                            <td>
                              {e.photo_id !== null && (
                                <LazyLoadComponent>
                                  <img
                                    alt="zabra_photo"
                                    className="patientListImg"
                                    src={e.thumbnail.thumbnail.photo_url}
                                  ></img>
                                </LazyLoadComponent>
                              )}
                            </td>
                            <td>{e.product.name}</td>
                            <td>{e.language.name}</td>
                            <td>
                              <NavLink
                                to={`editPresentation/${e.id}`}
                                className="icon"
                              >
                                <EditIcon />
                              </NavLink>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer/>
    </>
  );
};

export { PresentationCatalog };
