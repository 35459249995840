import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { OffCanvas, OffCanvasMenu } from "react-offcanvas";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";

const Menu = () => {
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    setMenu(false);
    // eslint-disable-next-line
  }, []);

  return (
    <OffCanvas
      width={500}
      transitionduration={300}
      effect={"parallax"}
      isMenuOpened={menu}
      position={"right"}
    >
      <MenuIcon onClick={() => setMenu((prevState) => !prevState)} />
      <OffCanvasMenu className="menu" style={{ top: "45px" }}>
        <ul>
          <li className="closeMenu">
            <div>
              <CloseIcon onClick={() => setMenu((prevState) => !prevState)} />
            </div>
          </li>
          <li>
            <NavLink to="/" exact activeClassName="active">
              Lista produktów
            </NavLink>
          </li>
          <li>
            <NavLink to="/photoCatalog" activeClassName="active">
              Katalog zdjęć
            </NavLink>
          </li>
          <li>
            <NavLink to="/descriptionCatalog" activeClassName="active">
              Katalog opisów
            </NavLink>
          </li>
          <li>
            <NavLink to="/zebraCatalog" activeClassName="active">
              Katalog modułów zebra
            </NavLink>
          </li>
          <li>
            <NavLink to="/presentationCatalog" activeClassName="active">
              Katalog prezentacji produktów
            </NavLink>
          </li>
        </ul>
      </OffCanvasMenu>
    </OffCanvas>
  );
};

export default Menu;
