import React, { useState, useEffect } from "react";
import { Form } from "./Form";
import { FormNames } from "./FormNames";
import { Loader } from "./Loader";
import { TopBar } from "./TopBar";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import useRefresh from "./useRefresh";
import Alert from "@material-ui/lab/Alert";
import qs from "qs";
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useHistory } from "react-router-dom";

const Layout = () => {
  const [product, setProduct] = useState([]);
  const [featuresGroup, setFeaturesGroup] = useState([]);
  const [loader, setLoader] = useState("loader");
  const [featuresCard, setFeaturesCard] = useState("loader");
  const [refreshFeatures, setRefreshFeatures] = useState(4);
  const [refreshProduct, setRefreshProduct] = useState(4);
  const [newFeaturesGroup, setNewFeaturesGroup] = useState(false);
  const [newSet, setNewSet] = useState(true);

  const history = useHistory();
  const API = process.env.REACT_APP_API;
  let accessToken = Cookies.get("accessToken");

  let mode = localStorage.getItem("mode");
  let feature_group_id = localStorage.getItem("feature_group_id");

  const isUseRefresh = useRefresh();
  const { ID_PRODUKTU } = useParams();

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let wait = (Math.floor(Math.random() * (5 - 1 + 1)) + 1) * 1000;
    refreshFeatures > 0 &&
      refreshFeatures < 4 &&
      setTimeout(getFeaturesGroup(), wait);
    // eslint-disable-next-line
  }, [refreshFeatures]);

  useEffect(() => {
    if (mode === "names") {
      getNames();
    } else {
      getFeaturesGroup();
    }
    // eslint-disable-next-line
  }, [newFeaturesGroup]);

  useEffect(() => {
    let wait = (Math.floor(Math.random() * (5 - 1 + 1)) + 1) * 1000;
    refreshProduct > 0 && refreshProduct < 4 && setTimeout(getProduct(), wait);
    // eslint-disable-next-line
  }, [refreshProduct]);

  const getProduct = () => {
    axios
      .get(`${API}/product/${ID_PRODUKTU}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setProduct(response.data);
        setLoader("products");
        setRefreshProduct(0);
      })
      .catch((error) => {
        error.message === "invalid refresh token"
          ? isUseRefresh.getRefreshToken()
          : setRefreshProduct((prevState) => prevState - 1);
        refreshProduct === 1 && setLoader("alert");
      });
  };

  function getNames() {
    axios
      .get(`${API}/product/${ID_PRODUKTU}/customizations`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setFeaturesGroup(response.data);
        setFeaturesCard("features");
        setRefreshFeatures(0);
        setNewSet((prevState) => !prevState);
      })
      .catch((error) => {
        error.message === "invalid refresh token"
          ? isUseRefresh.getRefreshToken()
          : setRefreshFeatures((prevState) => prevState - 1);
        refreshFeatures === 1 && setFeaturesCard("alert");
      });
  }

  function getFeaturesGroup() {
    axios
      .post(
        `${API}/product/${ID_PRODUKTU}/features`,
        qs.stringify({
          mode: mode,
          feature_group_id: feature_group_id,
        }),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setFeaturesGroup(response.data);
        setFeaturesCard("features");
        setRefreshFeatures(0);
        setNewSet((prevState) => !prevState);
      })
      .catch((error) => {
        error.message === "invalid refresh token"
          ? isUseRefresh.getRefreshToken()
          : setRefreshFeatures((prevState) => prevState - 1);
        refreshFeatures === 1 && setFeaturesCard("alert");
      });
  }

  return (
    <>
      {loader === "loader" && <Loader />}
      {loader === "alert" && (
        <Alert variant="filled" severity="error">
          Błąd pobierania danych! Spróbuj ponownie za chwilę.
        </Alert>
      )}
      {loader === "products" && (
        <div>
          <TopBar />
          <div className="row">
            <div className="card product">
              <div className="card-image">
                {product.photos !== undefined && product.photos !== null && (
                  <img src={product.photos[0].photo_url} alt="product" />
                )}
              </div>
              <div className="card-content">
                <span className="card-title">{product.name}</span>
                <p>{product.text}</p>
                <p>{product.description_short}</p>
              </div>
              <div>
                {/* <div className="card-action">
                  <a
                    href={product.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tu jest link
                  </a>
                </div> */}
              </div>
              <div className="sBtn backBtn">
                <Button
                  onClick={() => {
                    history.push("/");
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<NavigateBeforeIcon />}
                >
                  Powrót do listy produktów
                </Button>
              </div>
            </div>
            <div className="card form">
              {featuresCard === "loader" && (
                <Loader changeColor={"changeColor"} />
              )}
              {featuresCard === "alert" && (
                <Alert variant="filled" severity="error">
                  This is an error alert !
                </Alert>
              )}
              {featuresCard === "features" && mode !== "names" && (
                <Form
                  featuresGroup={featuresGroup}
                  productId={ID_PRODUKTU}
                  mode={mode}
                  setNewFeaturesGroup={() => {
                    setNewFeaturesGroup((prevState) => !prevState);
                  }}
                  newSet={newSet}
                />
              )}
              {featuresCard === "features" && mode === "names" && (
                <FormNames
                  featuresGroup={featuresGroup}
                  productId={ID_PRODUKTU}
                  mode={mode}
                  setNewFeaturesGroup={() => {
                    setNewFeaturesGroup((prevState) => !prevState);
                  }}
                  newSet={newSet}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { Layout };
