import React, { useEffect, useState } from "react";

const Input = ({ feature, error, handleChangeInput }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (feature.value === null || feature.value === undefined) {
      setValue("");
    } else {
      setValue(feature.value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={
        error !== undefined && error !== "" && value === "" ? " emptyCell" : ""
      }
    >
      <div className="form-part input inputText">
        <label>
          {feature.name}
          <input
            type="text"
            className="validate"
            name={feature.id}
            minLength="1"
            value={value}
            onChange={(e) => {
              handleChangeInput(e);
              setValue(e.target.value);
            }}
          />
        </label>
      </div>
    </div>
  );
};

export { Input };
