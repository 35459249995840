import React, { useState, useEffect } from "react";
import { TopBar } from "./TopBar";
import Cookies from "js-cookie";
import axios from "axios";
import useRefresh from "./useRefresh";
import Button from "@material-ui/core/Button";
import Modal from "react-modal";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { Editor } from "@tinymce/tinymce-react";
import SaveIcon from "@material-ui/icons/Save";
import qs from "qs";
import { useParams, useHistory } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";

Modal.setAppElement("#root");

const EditDescription = () => {
  const [languages, setLanguages] = useState([]);
  const [language_id, setLanguageId] = useState("");
  const [text, setText] = useState("");
  const [header, setHeader] = useState("");
  const [refreshList, setRefreshList] = useState(4);
  const [alert, setAlert] = useState("");

  const API = process.env.REACT_APP_API;

  let history = useHistory();

  const { description_id } = useParams();

  useEffect(() => {
    const end = () => {
      setAlert("");
    };

    let endAlert = setTimeout(end, 5000);
    // eslint-disable-next-line
  }, [alert]);

  useEffect(() => {
    setAlert(Cookies.get("alert"));
    const end = () => {
      let time = 4 / 24;
      Cookies.set("alert", "", { expires: time });
      setAlert("");
    };

    let endAlert = setTimeout(end, 5000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getLanguages();
    getDescription();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let wait = (Math.floor(Math.random() * (5 - 1 + 1)) + 1) * 1000;
    refreshList > 0 && refreshList < 4 && setTimeout(getLanguages(), wait);
    // eslint-disable-next-line
  }, [refreshList]);

  let accessToken = Cookies.get("accessToken");
  const isUseRefresh = useRefresh();

  const getDescription = () => {
    axios
      .get(`${API}/description/${description_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setLanguageId(response.data.language.id);
        setText(response.data.text);
        setHeader(response.data.header);
      })
      .catch(() => {});
  };

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const getLanguages = () => {
    axios
      .get(`${API}/languages`, config)
      .then((response) => {
        setLanguages(response.data);
      })
      .catch((error) => {
        error.message === "invalid refresh token"
          ? isUseRefresh.getRefreshToken()
          : setRefreshList((prevState) => prevState - 1);
      });
  };

  const handleChange = (e) => {
    setLanguageId(e.target.value);
  };

  const handleEditorChange = (content, editor) => {
    setText(content);
  };

  const submit = () => {
    if (
      language_id.toString().length > 0 &&
      text.length > 0
    ) {
      axios
        .post(
          `${API}/description/${description_id}/put`,
          qs.stringify({ language_id, header, text }),
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((form) => {
          if (form !== undefined) {
            let time = new Date();
            time.setTime(time.getTime() + 15 * 1000);
            Cookies.set("alert", "editDescription", { expires: time });
            window.scrollTo(0, 0);
            window.location.reload();
          }
        })
        .catch(() => {});
    } else {
      window.scrollTo(0, 0);
      setAlert("noData");
    }
  };

  const deleteDescription = () => {
    // axios
    //   .get(`${API}/description/${description_id}/delete`, {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   })
    //   .then(() => {
    //     let time = new Date();
    //     time.setTime(time.getTime() + 15 * 1000);
    //     Cookies.set("alert", "deleteDescription", { expires: time });
    //     history.push({
    //       pathname: "/descriptionCatalog",
    //     });
    //   })
    //   .catch(() => {});
  };

  return (
    <>
      <TopBar />
      <div className="alert">
        {alert === "noData" && (
          <Alert variant="filled" severity="Success">
            Uzupełnij wszystkie pola!
          </Alert>
        )}
        {alert === "newDescription" && (
            <Alert variant="filled" severity="Success">
              Opis został dodany!
            </Alert>
        )}
        {alert === "editDescription" && (
            <Alert variant="filled" severity="Success">
              Opis został edytowany!
            </Alert>
        )}
      </div>
      <div className="divWithTable divAddZebra">
        <div className="divWithHeading">
          <div className="onlyZebra">
            <h2 className="card-title">Edytuj opis</h2>
            <div className="addZebra">
              <div className="addPresentationPart1">
                <div className="addZebraPart1">
                  <h3>PDEID</h3>

                  <input
                      value={description_id}
                      type="text"
                      className="readonly"
                  />

                  <h3>1. Wybierz język</h3>
                  <FormControl>
                    <Select
                      value={language_id}
                      name="language_id"
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {/* <option value="" disabled></option> */}
                      {languages.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <h3>2. Dodaj nagłówek</h3>
                  <input
                      value={header}
                      type="text"
                      className="validate"
                      minLength="1"
                      onChange={(e) => {
                        setHeader(e.target.value);
                      }}
                  />

                </div>
                <div>
                  <h3>3. Dodaj tekst</h3>
                  <Editor
                    value={text}
                    init={{
                      height: 450,
                      width: 700,
                      menubar: false,
                      entity_encoding: "raw",

                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={handleEditorChange}
                  />
                </div>
              </div>
              <div className="buttonBox">
                <Button
                  className="saveBtn"
                  onClick={() => {
                    history.push("/descriptionCatalog");
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<NavigateBeforeIcon />}
                >
                  Powrót
                </Button>
                <div>
                  {/*<Button*/}
                  {/*  className="saveBtn deleteBtn"*/}
                  {/*  onClick={() => deleteDescription()}*/}
                  {/*  variant="contained"*/}
                  {/*  size="large"*/}
                  {/*  startIcon={<DeleteIcon />}*/}
                  {/*>*/}
                  {/*  Usuń*/}
                  {/*</Button>*/}
                  <Button
                    className="saveBtn"
                    onClick={() => submit()}
                    variant="contained"
                    size="large"
                    color="primary"
                    startIcon={<SaveIcon />}
                  >
                    Zapisz
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { EditDescription };
