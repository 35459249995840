import React, { useState, useEffect } from "react";
import { Switch } from "./Switch";
import { Radio } from "./Radio";
import { Range } from "./Range";
import { Input } from "./Input";
import { Int } from "./Int";
import { Decimal } from "./Decimal";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import axios from "axios";
import Cookies from "js-cookie";
import qs from "qs";
import { Loader } from "./Loader";
import Alert from "@material-ui/lab/Alert";

const Form = ({
  featuresGroup,
  setNewFeaturesGroup,
  productId,
  mode,
  newSet,
}) => {
  const [set, setSet] = useState({});
  const [error, setError] = useState("");
  const [errorRadio, setErrorRadio] = useState(true);
  const [empty, setEmpty] = useState(false);
  const [next, setNext] = useState();
  const [prev, setPrev] = useState();
  const [send, setSend] = useState(false);
  const [inputForm, setInputForm] = useState({});
  const [loader, setLoader] = useState(false);

  const API = process.env.REACT_APP_API;
  let accessToken = Cookies.get("accessToken");

  useEffect(() => {
    if (featuresGroup !== null) {
      setNext(featuresGroup.next);
      setPrev(featuresGroup.prev);
    }
  }, [featuresGroup]);

  useEffect(() => {
    featuresGroup !== null &&
      featuresGroup.features.map((e, i) => {
        set[i] = [];
        set[i]["feature_id"] = e.id;
        set[i]["feature_value"] = e.value;
        return set;
      });
    // eslint-disable-next-line
  }, [newSet]);

  useEffect(() => {
    if (featuresGroup !== null && featuresGroup.group.scale === "radio") {
      featuresGroup.features.map((e, i) => {
        if (set[i] !== undefined && inputForm == set[i]["feature_id"]) {
          set[i]["feature_value"] = 1;
          setSend(true);
          setErrorRadio(false);
        } else if (set[i] !== undefined && inputForm !== set[i]["feature_id"]) {
          set[i]["feature_value"] = 0;
        }
        return e;
      });
    }
    // eslint-disable-next-line
  }, [inputForm, newSet]);

  useEffect(() => {
    if (featuresGroup !== null && featuresGroup.group.scale !== "radio") {
      let a = 0;
      featuresGroup.features.map((e, i) => {
        if (e.id == inputForm.feature_id && set[i] !== undefined) {
          set[i]["feature_value"] = inputForm.feature_value;
        }
        if (set[i] !== undefined) {
          if (
            set[i]["feature_value"] !== null &&
            set[i]["feature_value"] !== undefined &&
            set[i]["feature_value"].toString().length >= 1
          ) {
            a = a + 1;
          }
        }
        return a;
      });
      if (featuresGroup.features.length == a) {
        setSend(true);
      } else {
        setSend(false);
      }
    }
    setSet(set);
    // eslint-disable-next-line
  }, [inputForm, newSet]);

  const handleChangeSwitch = (e, value, name) => {
    let val;
    if (value === true) {
      val = 1;
    } else if (value === false) {
      val = 0;
    }
    setInputForm(() => {
      return { feature_id: name, feature_value: val };
    });
  };

  const handleChangeRange = (e, value, name) => {
    setInputForm(() => {
      return { feature_id: name, feature_value: value };
    });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setInputForm(() => {
      return { feature_id: name, feature_value: value };
    });
  };

  const validation = () => {
    if (
      featuresGroup.group.scale !== "bool" &&
      featuresGroup.group.scale !== "fuzzy" &&
      (featuresGroup.features.length > Object.keys(set).length ||
        send === false)
    ) {
      // console.log(featuresGroup.features.length);
      // console.log(Object.keys(set).length);
      // console.log(set);
      // console.log(send);
      setError("Odpowiedz na wszystkie pytania!");
    } else {
      setError("");
    }
  };

  const submit = () => {
    validation();
    setEmpty(true);
    if (send === true) {
      // setLoader(true);
      axios
        .post(
          `${API}/product/${productId}/features/put`,
          qs.stringify({ set }),
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((form) => {
          if (form.data.result === "ok") {
            setError("");
            setEmpty(false);
            setSend(false);
            localStorage.setItem("feature_group_id", featuresGroup.next);
            if (next !== null) {
              setNewFeaturesGroup();
            }
          }
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  };

  const goNext = () => {
    setError("");
    setEmpty(false);
    setSend(false);
    localStorage.setItem("feature_group_id", featuresGroup.next);
    setNewFeaturesGroup();
    setSet({});
  };

  const goPrev = () => {
    setError("");
    setEmpty(false);
    setSend(false);
    localStorage.setItem("feature_group_id", featuresGroup.prev);
    setNewFeaturesGroup();
    setSet({});
  };

  return (
    <>
      {loader === true ? (
        <Loader changeColor={"changeColor"} />
      ) : featuresGroup === null ? (
        <Alert variant="filled" severity="success">
          Wszystkie dane o produkcie zostały już uzupełnione!
        </Alert>
      ) : (
        <form onSubmit={submit}>
          {
            {
              bool: (
                <>
                  <div>
                    <p className="title">{featuresGroup.group.title}</p>
                    <p className="title2">{featuresGroup.group.description}</p>
                  </div>
                  {featuresGroup.features.map((e, i) => {
                    return (
                      <div className="questionList" key={i}>
                        <Switch
                          feature={e}
                          handleChangeSwitch={handleChangeSwitch}
                          mode={mode}
                          empty={empty}
                        />
                      </div>
                    );
                  })}
                </>
              ),
              radio: (
                <>
                  <div>
                    <Radio
                      featuresGroup={featuresGroup}
                      errorRadio={errorRadio}
                      empty={empty}
                      setInputForm={setInputForm}
                    />
                  </div>
                </>
              ),
              fuzzy: (
                <>
                  <div>
                    <p className="title">{featuresGroup.group.title}</p>
                    <p className="title2">{featuresGroup.group.description}</p>
                  </div>
                  {featuresGroup.features.map((e, i) => {
                    return (
                      <div className="questionList" key={i}>
                        <Range
                          feature={e}
                          empty={empty}
                          handleChangeRange={handleChangeRange}
                        />
                      </div>
                    );
                  })}
                </>
              ),
              text: (
                <>
                  <div>
                    <p className="title">{featuresGroup.group.title}</p>
                    <p className="title2">{featuresGroup.group.description}</p>
                  </div>
                  {featuresGroup.features.map((e, i) => {
                    return (
                      <div className="questionList" key={i}>
                        <Input
                          feature={e}
                          handleChangeInput={handleChangeInput}
                          error={error}
                        />
                      </div>
                    );
                  })}
                </>
              ),
              int: (
                <>
                  <div>
                    <p className="title">{featuresGroup.group.title}</p>
                    <p className="title2">{featuresGroup.group.description}</p>
                  </div>
                  {featuresGroup.features.map((e, i) => {
                    return (
                      <div className="questionList" key={i}>
                        <Int
                          feature={e}
                          handleChangeInput={handleChangeInput}
                          error={error}
                        />
                      </div>
                    );
                  })}
                </>
              ),
              decimal: (
                <>
                  <div>
                    <p className="title">{featuresGroup.group.title}</p>
                    <p className="title2">{featuresGroup.group.description}</p>
                  </div>
                  {featuresGroup.features.map((e, i) => {
                    return (
                      <div className="questionList" key={i}>
                        <Decimal
                          feature={e}
                          handleChangeInput={handleChangeInput}
                          error={error}
                        />
                      </div>
                    );
                  })}
                </>
              ),
            }[featuresGroup.group.scale]
          }
          <p className="error">{error}</p>

          <div className="buttonBox">
            <div className="sBtn">
              {prev !== null && (
                <Button
                  onClick={() => {
                    goPrev();
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<NavigateBeforeIcon />}
                ></Button>
              )}
              {next !== null && (
                <Button
                  onClick={() => {
                    goNext();
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                  endIcon={<NavigateNextIcon />}
                ></Button>
              )}
            </div>
            <Button
              className="saveBtn"
              onClick={() => submit()}
              variant="contained"
              size="large"
              color="primary"
              startIcon={<SaveIcon />}
            >
              Zapisz
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

export { Form };
