import React, {useEffect, useState} from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdjust} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import useLogOut from "./useLogOut";
import useRefresh from "./useRefresh";
import Menu from "./Menu";
import { NavLink } from "react-router-dom";

const TopBar = () => {
  const [contrast, setContrast]= useState(Cookies.get("contrast") == "true" ? true : false)
  const [textSize, setTextSize]= useState(Cookies.get("textSize") == "true" ? true : false)
  const isUseLogOut = useLogOut();
  const isUseRefresh = useRefresh();

  let userName = Cookies.get("name");

  useEffect(() => {
  let mainDiv = document.getElementById("accessibility")
  let modalDivs = document.getElementsByClassName("ReactModalPortal")

  if(contrast == true ){
   mainDiv.classList.add("contrast");
    for (let i = 0; i < modalDivs.length; i++) {
      modalDivs[i].classList.add('contrastModal');
    }
  } else if (contrast == false  && mainDiv.classList.contains("contrast")) {
    mainDiv.classList.remove("contrast")
     for (let i = 0; i < modalDivs.length; i++) {
      modalDivs[i].classList.remove('contrastModal');
    }
  }
  let time = 30
  Cookies.set("contrast", contrast, { expires: time });
    // eslint-disable-next-line
  }, [contrast]);



  useEffect(() => {
    let mainDiv = document.querySelector("#accessibility > div")
    let modalDivs = document.getElementsByClassName("ReactModalPortal")
  
    if(textSize == true ){
     mainDiv.classList.add("textSize");
      for (let i = 0; i < modalDivs.length; i++) {
        modalDivs[i].classList.add('textSizeModal');
      }
    } else if (contrast == false  && mainDiv.classList.contains("textSize")) {
      mainDiv.classList.remove("textSize")
       for (let i = 0; i < modalDivs.length; i++) {
        modalDivs[i].classList.remove('textSizeModal');
      }
    }

    let time = 30
    Cookies.set("textSize", textSize, { expires: time });
    // eslint-disable-next-line
  }, [textSize]);


  const click = () => {
    isUseLogOut.getLogOut();
    return isUseLogOut.error === true && isUseRefresh.getRefreshToken();
  };

  const changeContrast = () => {
    setContrast(!contrast)
  }

  const changeTextSize = () => {
    setTextSize(!textSize)
  }


  return (
    <div className="topBar">
      <div>
        <NavLink to="/">
          <img
            src="images/logo2.png"
            width="91px"
            height="40px"
            alt="Handlosfera.pl"
          />
        </NavLink>
      </div>
      <div>
        <AccountCircleIcon />
        <p>{userName}</p>
        <Menu />
        <div>
        <ExitToAppIcon onClick={() => click()} />
        <FontAwesomeIcon icon={faAdjust} 
        onClick={() => changeContrast()}
        />
        <FormatSizeIcon 
        onClick={() => changeTextSize()}
        />
        </div>
      </div>
    </div>
  );
};

export { TopBar };
