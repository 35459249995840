import React, { useState, useEffect } from "react";
import { TopBar } from "./TopBar";
import { AddPhoto } from "./AddPhoto";
import { SelectPhoto } from "./SelectPhoto";
import { SelectProduct } from "./SelectProduct";
import { SelectZebra } from "./SelectZebra";
import Cookies from "js-cookie";
import axios from "axios";
import useRefresh from "./useRefresh";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { Editor } from "@tinymce/tinymce-react";
import SaveIcon from "@material-ui/icons/Save";
import qs from "qs";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useParams, useHistory } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";

Modal.setAppElement("#root");

const EditPresentation = () => {
  const [languages, setLanguages] = useState([]);
  const [product_id, setProduct_id] = useState("");
  const [language_id, setLanguage_id] = useState("");
  const [product_description_short, setProduct_description_short] =
    useState("");
  const [refreshList, setRefreshList] = useState(4);
  const [addPhoto, setAddPhoto] = useState(false);
  const [addPhotos, setAddPhotos] = useState(false);
  const [addZebra, setAddZebra] = useState(false);
  const [selectPhotos, setSelectPhotos] = useState(false);
  const [selectPhoto, setSelectPhoto] = useState(false);
  const [selectProduct, setSelectProduct] = useState(false);
  const [showSelectedProduct, setShowSelectedProduct] = useState([]);
  const [showPhotoLoader, setShowPhotoLoader] = useState("false");
  const [thumbnail_id, setThumbnail_id] = useState("");
  const [showSelectedPhoto, setShowSelectedPhoto] = useState("");
  const [showSelectedPhotos, setShowSelectedPhotos] = useState([]);
  const [showSelectedZebras, setShowSelectedZebras] = useState([]);
  const [product_facts, setProduct_facts] = useState("");
  const [product_facts_prefix, setProduct_factsPrefix] = useState("");
  const [product_facts_suffix, setProduct_factsSuffix] = useState("");
  const [product_name, setProduct_name] = useState("");
  const [photos_id, setPhotos_id] = useState("");
  const [zebra_id, setZebra_id] = useState("");
  const [photosLoader, setPhotosLoader] = useState(0);
  const [allPhotosId, setAllPhotosId] = useState([]);
  const [allZebrasId, setAllZebrasId] = useState([]);
  const [alert, setAlert] = useState("");

  const API = process.env.REACT_APP_API;

  const history = useHistory();

  let accessToken = Cookies.get("accessToken");
  const isUseRefresh = useRefresh();

  const { presentation_id } = useParams();

  useEffect(() => {
    const end = () => {
      setAlert("");
    };

    let endAlert = setTimeout(end, 5000);
    // eslint-disable-next-line
  }, [alert]);

  useEffect(() => {
    setAlert(Cookies.get("alert"));
    const end = () => {
      let time = 4 / 24;
      Cookies.set("alert", "", { expires: time });
      setAlert("");
    };

    let endAlert = setTimeout(end, 5000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getLanguages();
    getPresentation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let wait = (Math.floor(Math.random() * (5 - 1 + 1)) + 1) * 1000;
    refreshList > 0 && refreshList < 4 && setTimeout(getLanguages(), wait);
    // eslint-disable-next-line
  }, [refreshList]);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const getLanguages = () => {
    axios
      .get(`${API}/languages`, config)
      .then((response) => {
        setLanguages(response.data);
      })
      .catch((error) => {
        error.message === "invalid refresh token"
          ? isUseRefresh.getRefreshToken()
          : setRefreshList((prevState) => prevState - 1);
      });
  };

  const getPresentation = () => {
    axios
      .get(`${API}/product-presentation/${presentation_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setProduct_name(response.data.product_name);
        setProduct_description_short(response.data.product_description_short);
        setProduct_facts(response.data.product_facts);
        setProduct_factsPrefix(response.data.product_facts_prefix);
        setProduct_factsSuffix(response.data.product_facts_suffix);
        setThumbnail_id(response.data.thumbnail.id);
        setProduct_id(response.data.product.id);
        setLanguage_id(response.data.language.id);

        if(response.data.photos!=null && response.data.photos.length>0)
        {
          for (let i = 0; i < response.data.photos.length; i++) {
            setAllPhotosId((prevState) => {
              return [...prevState, response.data.photos[i].photo.id];
            });
          }
        }

        if(response.data.zebras!=null && response.data.zebras.length>0)
        {
          for (let i = 0; i < response.data.zebras.length; i++) {
            setAllZebrasId((prevState) => {
              return [...prevState, response.data.zebras[i].zebra.id];
            });
          }
        }

        if(response.data.photos!=null && response.data.photos.length>0)
        {
          for (let i = 0; i < response.data.photos.length; i++) {
            setShowSelectedPhotos((prevState) => {
              return [
                ...prevState,
                {
                  id: response.data.photos[i].photo.id,
                  position: parseFloat(response.data.photos[i].position),
                  img: response.data.photos[i].photo.thumbnail.photo_url,
                  name: response.data.photos[i].photo.thumbnail.photo_url,
                },
              ];
            });
          }
        }

        if(response.data.zebras!=null && response.data.zebras.length>0)
        {
          for (let i = 0; i < response.data.zebras.length; i++) {
            setShowSelectedZebras((prevState) => {
              return [
                ...prevState,
                {
                  id: response.data.zebras[i].zebra.id,
                  position: parseFloat(response.data.zebras[i].position),
                  img: response.data.zebras[i].zebra.photo.thumbnail.photo_url,
                  name: response.data.zebras[i].zebra.photo.thumbnail.photo_url,
                  description: response.data.zebras[i].zebra.description,
                },
              ];
            });
          }
        }
      })
      .catch(() => {});
  };

  const closeModalProduct = () => {
    setAddPhoto(false);
    setSelectProduct(false);
  };

  const closeModalZebra = () => {
    setAddZebra(false);
    // setSelectZebra(false);
  };

  const closeModalPhoto = () => {
    setAddPhoto(false);
    setAddPhotos(false);
    setSelectPhoto(false);
    setSelectPhotos(false);
  };

  useEffect(() => {
    setShowSelectedProduct("");
    getSelectedProduct();
    // eslint-disable-next-line
  }, [product_id]);

  useEffect(() => {
    setShowSelectedPhoto("");
    getSelectedPhoto();
    // eslint-disable-next-line
  }, [thumbnail_id]);

  useEffect(() => {
    // setShowSelectedPhoto("");
    getSelectedPhotos();
    // eslint-disable-next-line
  }, [photos_id]);

  useEffect(() => {
    // setShowSelectedPhoto("");
    getSelectedZebras();
    // eslint-disable-next-line
  }, [zebra_id]);

  const getSelectedPhotos = () => {
    if (photos_id !== "" && photos_id !== undefined) {
      if (showSelectedPhotos.find((el) => el.id === photos_id) === undefined) {
        axios
          .get(`${API}/photo/${photos_id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            if (response.data.sizes !== null) {
              setShowSelectedPhotos((prevState) => {
                return [
                  ...prevState,
                  {
                    id: photos_id,
                    position:
                      showSelectedPhotos.length === 0 && photosLoader === 0
                        ? 1
                        : showSelectedPhotos.length !== 0
                        ? showSelectedPhotos[showSelectedPhotos.length - 1]
                            .position +
                          photosLoader +
                          1
                        : photosLoader + 1,
                    img: response.data.thumbnail.photo_url,
                    name: response.data.thumbnail.photo_url,
                  },
                ];
              });
            } else {
              setPhotosLoader((prevState) => prevState + 1);
              getSinglePhoto(photos_id);
            }
          });
      }
    }
  };

  const getSelectedZebras = () => {
    if (zebra_id !== "" && zebra_id !== undefined) {
      if (showSelectedZebras.find((el) => el.id === zebra_id) === undefined) {
        axios
          .get(`${API}/zebra/${zebra_id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            // if (response.data.photo !== null) {
            setShowSelectedZebras((prevState) => {
              return [
                ...prevState,
                {
                  id: zebra_id,
                  position:
                    showSelectedZebras.length === 0
                      ? 1
                      : showSelectedZebras[showSelectedZebras.length - 1]
                          .position + 1,
                  img: response.data.photo.thumbnail.photo_url,
                  name: response.data.description,
                  description: response.data.description,
                },
              ];
            });
          });
      }
    }
  };

  const getSinglePhoto = (photoId) => {
    axios
      .get(`${API}/photo/${photoId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.data.sizes !== null) {
          if (
            showSelectedPhotos.find((el) => el.id === photos_id) === undefined
          ) {
            {
              setPhotosLoader((prevState) => prevState - 1);
              setShowSelectedPhotos((prevState) => {
                return [
                  ...prevState,
                  {
                    id: photoId.toString(),
                    position:
                      showSelectedPhotos.length === 0 && photosLoader === 0
                        ? 1
                        : showSelectedPhotos.length !== 0
                        ? showSelectedPhotos[showSelectedPhotos.length - 1]
                            .position +
                          photosLoader +
                          1
                        : photosLoader + 1,
                    img: response.data.thumbnail.photo_url,
                    name: response.data.thumbnail.photo_url,
                  },
                ];
              });
            }
          }
        } else {
          setTimeout(() => {
            getSinglePhoto(photoId);
          }, 20000);
        }
      })
      .catch((error) => {});
  };

  const getSelectedProduct = () => {
    if (product_id !== "") {
      axios
        .get(`${API}/product/${product_id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          if (response.data.sizes !== null) {
            setShowSelectedProduct(response.data);

            if(product_name=="")
            {
              setProduct_name(response.data.name);
            }
          }
        })
        .catch((error) => {});
    }
  };

  const getSelectedPhoto = () => {
    if (thumbnail_id !== "") {
      axios
        .get(`${API}/photo/${thumbnail_id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          if (response.data.sizes !== null) {
            setShowPhotoLoader(false);
            setShowSelectedPhoto(response.data.thumbnail.photo_url);
          } else {
            setShowPhotoLoader(true);
            setTimeout(() => {
              getSelectedPhoto();
            }, 60000);
          }
        })
        .catch((error) => {});
    }
  };

  const handleChange = (e) => {
    setLanguage_id(e.target.value);
  };

  const submit = () => {
    if (
        product_id.toString().length > 0 &&
        language_id.toString().length > 0 &&
        thumbnail_id.toString().length > 0
    ) {
      var photos = [];
      var zebras = [];

      if(showSelectedPhotos!=null && showSelectedPhotos.length>0)
      {
        photos = showSelectedPhotos.map(function(photo){
          return {id: photo.id, position: photo.position}
        });
      }

      if(showSelectedZebras!=null && showSelectedZebras.length>0)
      {
        zebras = showSelectedZebras.map(function(zebra){
          return {id: zebra.id, position: zebra.position}
        });
      }

      axios
          .post(
              `${API}/product-presentation/${presentation_id}/put`,
              qs.stringify({
                product_id,
                language_id,
                thumbnail_id,
                product_name,
                product_description_short,
                product_facts_prefix,
                product_facts_suffix,
                photos,
                zebras,
              }),
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
          )
          .then((response) => {
            setLanguage_id("");
            setProduct_id("");
            setProduct_description_short("");
            setProduct_name("");
            setProduct_facts("");
            setProduct_factsPrefix("");
            setProduct_factsSuffix("");
            setShowSelectedProduct("");
            setShowSelectedPhoto("");
            setShowSelectedPhotos([]);
            setShowSelectedZebras([]);

            let time = new Date();
            time.setTime(time.getTime() + 15 * 1000);
            Cookies.set("alert", "editPresentation", { expires: time });
            window.scrollTo(0, 0);
            window.location.reload();
          })
          .catch((error) => {});
    }
    else {
      window.scrollTo(0, 0);
      setAlert("noData");
    }
  };

  const addProductPresentationPhotos = (i) => {
    let photo_id = showSelectedPhotos[i].id;
    let position = showSelectedPhotos[i].position;
    axios
      .post(
        `${API}/product-presentation/${presentation_id}/photo/put`,
        qs.stringify({
          photo_id,
          position,
        }),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {})
      .catch((error) => {});
  };

  const addProductPresentationZebras = (i) => {
    let zebra_id = showSelectedZebras[i].id;
    let position = showSelectedZebras[i].position;
    axios
      .post(
        `${API}/product-presentation/${presentation_id}/zebra/put`,
        qs.stringify({
          zebra_id,
          position,
        }),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {})
      .catch((error) => {});
  };

  const deletePresentation = () => {
    axios
      .get(`${API}/product-presentation/${presentation_id}/delete`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(() => {
        let time = new Date();
        time.setTime(time.getTime() + 15 * 1000);
        Cookies.set("alert", "deletePresentation", { expires: time });
        history.push({
          pathname: "/presentationCatalog",
        });
      })
      .catch(() => {});
  };

  // const myList = showSelectedPhotos.map((el) => (
  //   <div className="list__card">
  //     <div className="list__card-left">
  //       <img src={el.img} alt={el.name} />
  //     </div>
  //   </div>
  // ));

  const deletePhoto = (id) => {
    const newShowPhotos = showSelectedPhotos.filter((photo) => photo.id !== id);
    setShowSelectedPhotos(newShowPhotos);
  };

  const deleteZebra = (id) => {
    const newShowZebras = showSelectedZebras.filter((zebra) => zebra.id !== id);
    setShowSelectedZebras(newShowZebras);
  };

  const gotoPhoto = (id) => {
    //TODO dodań osługę jak będzie już edycja zdjęć
  };

  const gotoZebra = (id) => {
    window.open("/#/editZebra/"+id);
  };

  const SortableItem = SortableElement(({ value, index }) => (
    <>
      <div className="list__card" index={index} key={value.id}>
        <div className="list__card-left">
          <button
              className="showSinglePhoto"
              onClick={() =>
                  value.description === undefined
                      ? gotoPhoto(value.id)
                      : gotoZebra(value.id)
              }
          >
            ...
          </button>

          <button
              className="deleteSinglePhoto"
              onClick={() =>
                  value.description === undefined
                      ? deletePhoto(value.id)
                      : deleteZebra(value.id)
              }
          >
            X
          </button>
          <img
            src={value.img}
            alt={value.name}
            title={value.description === undefined
                ? "PHLID: "+value.id
                : "PZID: "+value.id
            }
            data-for={value.id}
          />
        </div>
      </div>
    </>
  ));

  const SortableList = SortableContainer(({ items, loader }) => {
    return (
      <div className="list">
        {items
          .sort((a, b) => a.position - b.position)
          .map((value, index) => (
            <SortableItem value={value} index={index} key={value.id} />
          ))}
        {[...Array(loader)].map((e, i) => (
          <div className="list__card" key={i}>
            <div className="list__card-left">
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          </div>
        ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let arr = arrayMove(showSelectedPhotos, oldIndex, newIndex);
    for (let i = 0; i < arr.length; i++) {
      arr[i].position = i;
    }
    setShowSelectedPhotos(arr);
  };

  const onSortEndZebras = ({ oldIndex, newIndex }) => {
    let arr = arrayMove(showSelectedZebras, oldIndex, newIndex);
    for (let i = 0; i < arr.length; i++) {
      arr[i].position = i;
    }
    setShowSelectedZebras(arr);
  };

  return (
    <>
      <TopBar />
      <div className="alert">
        {alert === "newPresentation" && (
            <Alert variant="filled" severity="Success">
              Prezentacja produktu została dodana!
            </Alert>
        )}
        {alert === "editPresentation" && (
            <Alert variant="filled" severity="Success">
              Prezentacja produktu została edytowana!
            </Alert>
        )}
        {alert === "noData" && (
            <Alert variant="filled" severity="Success">
              Uzupełnij wszystkie pola!
            </Alert>
        )}
      </div>
      <div className="divWithTable divAddZebra">
        <div className="divWithHeading">
          <div className="divAddPresentation">
            <h2 className="card-title">Edytuj prezentację produktu</h2>
            <div className="addZebra">
              <div className="addPresentationPart1">
                <div className="addZebraPart1">
                  <h3>PPID</h3>

                  <input
                      value={presentation_id}
                      type="text"
                      className="readonly"
                  />

                  <h3>1. Wybierz produkt</h3>
                  <Button
                    className="saveBtn"
                    onClick={() => setSelectProduct(true)}
                    variant="contained"
                    size="large"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    Wybierz produkt
                  </Button>

                  <Modal
                    isOpen={selectProduct}
                    onRequestClose={() => closeModalProduct()}
                    className="ReactModalPortal"
                    overlayClassName="ReactModal__Overlay"
                  >
                    <div className="insideModal">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => closeModalProduct()}
                      />
                      <SelectProduct
                        setProduct_id={setProduct_id}
                        closeModalProduct={() => closeModalProduct()}
                      />
                    </div>
                  </Modal>

                  {showSelectedProduct!=="" ?
                      (<h4>PID: {showSelectedProduct.id}, {showSelectedProduct.name}</h4>)
                      : ""}

                  <h3>2. Dodaj nazwę produktu</h3>
                  <input
                      value={product_name}
                      type="text"
                      className="validate "
                      minLength="1"
                      onChange={(e) => {
                        setProduct_name(e.target.value);
                      }}
                  />

                  <h3>3. Wybierz język</h3>
                  <FormControl>
                    <Select
                      value={language_id}
                      name="language_id"
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {languages.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <h3>4. Wybierz miniaturę</h3>
                  <Modal
                    isOpen={addPhoto}
                    onRequestClose={() => closeModalPhoto()}
                    className="ReactModalPortal"
                    overlayClassName="ReactModal__Overlay"
                  >
                    <div className="insideModal">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => closeModalPhoto()}
                      />
                      <AddPhoto
                        setPhoto_id={setThumbnail_id}
                        multiple={false}
                      />
                    </div>
                  </Modal>
                  <Button
                    className="saveBtn"
                    onClick={() => setAddPhoto(true)}
                    variant="contained"
                    size="large"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    Dodaj nowe zdjęcie
                  </Button>

                  <Modal
                    isOpen={selectPhoto}
                    onRequestClose={() => closeModalPhoto()}
                    className="ReactModalPortal"
                    overlayClassName="ReactModal__Overlay"
                  >
                    <div className="insideModal">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => closeModalPhoto()}
                      />
                      <SelectPhoto
                        setPhoto_id={setThumbnail_id}
                        multiple={false}
                        closeModalPhoto={() => closeModalPhoto()}
                      />
                    </div>
                  </Modal>

                  <Button
                    className="saveBtn"
                    onClick={() => setSelectPhoto(true)}
                    variant="contained"
                    size="large"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    Wybierz zdjęcie z katalogu
                  </Button>
                  <div className="showSelectedPhoto addZebraLoader">
                    {showPhotoLoader === true && (
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    )}
                    {showSelectedPhoto !== "" && (
                      <img alt="selected_photo" src={showSelectedPhoto}></img>
                    )}
                  </div>
                </div>
                <div className="addZebraPart2">
                  <h3>5. Dodaj krótki opis</h3>
                  <input
                    value={product_description_short}
                    type="text"
                    className="validate shortDesription"
                    minLength="1"
                    onChange={(e) => {
                      setProduct_description_short(e.target.value);
                    }}
                  />

                  <h3>6. Dodaj prefiks faktów</h3>
                  <input
                      value={product_facts_prefix}
                      type="text"
                      onChange={(e) => {
                        setProduct_factsPrefix(e.target.value);
                      }}
                  />

                  <h3>7. Dodaj sufiks faktów</h3>
                  <input
                      value={product_facts_suffix}
                      type="text"
                      onChange={(e) => {
                        setProduct_factsSuffix(e.target.value);
                      }}
                  />

                  <h3>8. Podgląd faktów</h3>
                  <Editor
                    disabled={true}
                    value={product_facts}
                    init={{
                      height: 280,
                      width: 700,
                      menubar: false,
                      toolbar: false,
                      statusbar: false,
                      entity_encoding: "raw",

                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],

                    }}
                  />
                </div>
              </div>
              <h3>7. Dodaj zdjęcia</h3>
              <Modal
                isOpen={addPhotos}
                onRequestClose={() => closeModalPhoto()}
                className="ReactModalPortal"
                overlayClassName="ReactModal__Overlay"
              >
                <div className="insideModal">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => closeModalPhoto()}
                  />
                  <AddPhoto setPhotos_id={setPhotos_id} multiple={true} />
                </div>
              </Modal>
              <Button
                className="saveBtn"
                onClick={() => setAddPhotos(true)}
                variant="contained"
                size="large"
                color="primary"
                startIcon={<AddIcon />}
              >
                Dodaj nowe zdjęcie
              </Button>
              <Modal
                isOpen={selectPhotos}
                onRequestClose={() => closeModalPhoto()}
                className="ReactModalPortal"
                overlayClassName="ReactModal__Overlay"
              >
                <div className="insideModal">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => closeModalPhoto()}
                  />
                  <SelectPhoto
                    setPhotos_id={setPhotos_id}
                    photos_id={photos_id}
                    multiple={true}
                    closeModalPhoto={() => closeModalPhoto()}
                  />
                </div>
              </Modal>
              <Button
                className="saveBtn"
                onClick={() => setSelectPhotos(true)}
                variant="contained"
                size="large"
                color="primary"
                startIcon={<AddIcon />}
              >
                Wybierz zdjęcie z katalogu
              </Button>
              <SortableList
                items={showSelectedPhotos}
                loader={photosLoader}
                onSortEnd={onSortEnd}
                axis="xy"
              />
              <h3>7. Dodaj zebry</h3>
              <Modal
                isOpen={addZebra}
                onRequestClose={() => closeModalZebra()}
                className="ReactModalPortal"
                overlayClassName="ReactModal__Overlay"
              >
                <div className="insideModal">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => closeModalZebra()}
                  />
                  <SelectZebra
                    setZebra_id={setZebra_id}
                    closeModalZebra={() => closeModalZebra()}
                  />
                </div>
              </Modal>
              <Button
                className="saveBtn"
                onClick={() => setAddZebra(true)}
                variant="contained"
                size="large"
                color="primary"
                startIcon={<AddIcon />}
              >
                Wybierz zebrę z katalogu
              </Button>
              <SortableList
                items={showSelectedZebras}
                loader={0}
                onSortEnd={onSortEndZebras}
                axis="xy"
              />
              <div className="buttonBox">
                <Button
                  className="saveBtn"
                  onClick={() => {
                    history.push("/presentationCatalog");
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<NavigateBeforeIcon />}
                >
                  Powrót
                </Button>

                <div>
                  <Button
                    className="saveBtn deleteBtn"
                    onClick={() => deletePresentation()}
                    variant="contained"
                    size="large"
                    startIcon={<DeleteIcon />}
                  >
                    Usuń
                  </Button>

                  <Button
                    className="saveBtn"
                    onClick={() => submit()}
                    variant="contained"
                    size="large"
                    color="primary"
                    startIcon={<SaveIcon />}
                  >
                    Zapisz
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { EditPresentation };
