import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Button from "@material-ui/core/Button";
import Modal from "react-modal";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import useRefresh from "./useRefresh";

const QuestionModal = ({ question, closeDeleteModal, url }) => {

    const API = process.env.REACT_APP_API;
    let accessToken = Cookies.get("accessToken");

    const isUseRefresh = useRefresh();

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/x-www-form-urlencoded",
        },
    };

    const urlAction = () => {
        axios
            .get(`${API}/${url}`, config)
            .then((response) => {
                closeDeleteModal(response);
            })
            .catch((error) => {
                if(error.message === "invalid refresh token")
                {
                    isUseRefresh.getRefreshToken()
                }
            });
    };

    return (
        <>
            {question}

            <br/>
            <br/>

            <Button
                onClick={() => closeDeleteModal()}
                variant="text"
                size="large"
                className="pull-left btn-no">
                Nie
            </Button>

            <Button
                onClick={() => urlAction()}
                variant="text"
                size="large"
                className="pull-right btn-yes">
                Tak
            </Button>
        </>
    );
};

export { QuestionModal };
