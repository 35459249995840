import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { Footer } from "./Footer";
import axios from "axios";
import qs from "qs";
import Cookies from "js-cookie";

const API = process.env.REACT_APP_API;

const Login = () => {
  const [user, setUser] = useState({
    email: " ",
    password: "",
  });
  const [error, setError] = useState({});
  const [showInfoLoginFailed, setShowInfoLoginFailed] = useState(false);

  let history = useHistory();
  let prevPath = localStorage.getItem("location");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const validation = () => {
    const reg = /^[a-z\d]+[\w\d.-]*@(?:[a-z\d]+[a-z\d-]+\.){1,5}[a-z]{2,6}$/i;
    const errors = {};
    errors.email = !reg.test(user?.email)
      ? "Podany email jest nieprawidłowy!"
      : "";
    const isError = Object.values(errors).findIndex((err) => err?.length) > -1;
    setError(errors);
    errors !== undefined && setShowInfoLoginFailed(false);
    return isError;
  };

  const submit = (e) => {
    e.preventDefault();
    if (validation()) return;
    let email = user.email;
    let password = user.password;
    axios
      .post(
        `${API}/signin`,
        qs.stringify({
          email: email,
          password: password,
        })
      )
      .then((form) => {
        const time = 12 / 24;
        Cookies.set("name", form.data.name, { expires: time });
        Cookies.set("accessToken", form.data.tokens.access_token.jwt, {
          expires: time,
        });
        Cookies.set("refreshToken", form.data.tokens.refresh_token.jwt, {
          expires: time,
        });
        prevPath !== null ? history.push(prevPath) : history.push("/");
      })
      .catch((error) => {
        error.length === undefined && setShowInfoLoginFailed(true);
      });
  };

  return (
    <div className="loginView">
    <div className="login">
      <div className="container">
        <div className="form-container">
          <form onSubmit={submit} className="input-field">
            <h1>Logowanie</h1>
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              value={user.email}
              onChange={handleChange}
            />
            <p>{error.email}</p>
            <input
              type="password"
              name="password"
              placeholder="Hasło"
              value={user.password}
              onChange={handleChange}
            />
            {showInfoLoginFailed && (
              <p>Logowanie nie powiodło się. Proszę spróbować ponownie.</p>
            )}
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
            >
              ZALOGUJ SIĘ
            </Button>
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel">
              <img
                src="images/logo2.png"
                width="228px"
                height="100px"
                alt="handlosfera logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export { Login };
