import React from "react";

const Loader = ({ changeColor }) => {
  return (
    <div className="gooey">
      <span
        className="dot"
        style={changeColor && { background: "black" }}
      ></span>
      <div className="dots">
        <span style={changeColor && { background: "black" }}></span>
        <span style={changeColor && { background: "black" }}></span>
        <span style={changeColor && { background: "black" }}></span>
      </div>
    </div>
  );
};

export { Loader };
