import React from "react";

const Footer = () => {

  return (
    <div className="footer">
        <a target="_blank" href="https://handlosfera.pl/strona/8041-projekty-ue">
          <img
            src="images/footer.png"
            // width="720px"
            // height="46px"
            alt="Handlosfera.pl"
          />
        </a>
    </div>
  );
};

export { Footer };
