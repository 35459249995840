import React from "react";
import ReactDOM from "react-dom";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import { ProductsList } from "./ProductsList";
import "./scss/App.scss";
import * as serviceWorker from "./serviceWorker";
import { Layout } from "./Layout";
import { Login } from "./Login";
// import { Graph } from "./Graph";
// import { Graph2 } from "./Graph2";
import Cookies from "js-cookie";
import { PhotoCatalog } from "./PhotoCatalog";
import { ZebraCatalog } from "./ZebraCatalog";
import { PresentationCatalog } from "./PresentationCatalog";
import { DescriptionCatalog } from "./DescriptionCatalog";
import { AddPhoto } from "./AddPhoto";
import { AddZebra } from "./AddZebra";
import { AddPresentation } from "./AddPresentation";
import { EditZebra } from "./EditZebra";
import { EditPresentation } from "./EditPresentation";
import { EditDescription } from "./EditDescription";
import { AddDescription } from "./AddDescription";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = Cookies.get("accessToken");

  const location = useLocation();
  let loc = location.pathname;
  localStorage.setItem("location", loc);

  return (
    <Route
      {...rest}
      render={() =>
        !token ? <Redirect to={{ pathname: "/login" }} /> : <Component />
      }
    />
  );
};


ReactDOM.render(
  <div id = "accessibility">
    <div>
      <React.StrictMode>
        <HashRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <ProtectedRoute exact path="/" component={ProductsList} />
            <ProtectedRoute path="/photoCatalog" component={PhotoCatalog} />
            <ProtectedRoute path="/addphoto" component={AddPhoto} />
            <ProtectedRoute path="/addZebra" component={AddZebra} />
            <ProtectedRoute path="/addPresentation" component={AddPresentation} />
            <ProtectedRoute path="/editZebra/:zebra_id" component={EditZebra} />
            <ProtectedRoute path="/editPresentation/:presentation_id" component={EditPresentation} />
            <ProtectedRoute path="/zebraCatalog" component={ZebraCatalog} />
            <ProtectedRoute path="/presentationCatalog" component={PresentationCatalog} />
            <ProtectedRoute path="/descriptionCatalog" component={DescriptionCatalog} />
            <ProtectedRoute path="/editDescription/:description_id" component={EditDescription} />
            <ProtectedRoute path="/addDescription" component={AddDescription} />
            <ProtectedRoute path="/product/:ID_PRODUKTU" component={Layout} />
            {/* <ProtectedRoute path="/graph" component={Graph} />
            <ProtectedRoute path="/graph2" component={Graph2} /> */}
          </Switch>
        </HashRouter>
      </React.StrictMode>
    </div>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
