import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import Cookies from "js-cookie";
import qs from "qs";
import { Loader } from "./Loader";
import { Name } from "./Name";
import { NameDisabled } from "./NameDisabled";
import Alert from "@material-ui/lab/Alert";

const FormNames = ({ featuresGroup, productId, newSet }) => {
  // eslint-disable-next-line
  const [set, setSet] = useState({});
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState(false);

  const API = process.env.REACT_APP_API;
  let accessToken = Cookies.get("accessToken");

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (value.length > 0) {
      set[name] = { variant: name, variant_name: value };
    } else {
      delete set[name];
    }
  };

  const submit = () => {
    for (let i = 1; i < 6; i++) {
      if (set[i] !== undefined) {
        axios
          .post(
            `${API}/product/${productId}/customizations/put`,
            qs.stringify({ set }),
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .then((form) => {
            window.location.reload();
          })
          .catch((error) => {
            setLoader(false);
          });
        break;
      } else if (i === 5) {
        setAlert(true);
      }
    }
  };

  const inputs = (featuresGroup) => {
    let name = [];
    let numbers = [1, 2, 3, 4, 5];

    for (let i = 1; i < 6; i++) {
      featuresGroup !== null &&
        featuresGroup.forEach((e) => {
          if (e.variant === i) {
            delete numbers[i - 1];
            name.push(
              <div className="input" key={i}>
                <div className="row">
                  <div className="form-part input inputText">
                    <label>
                      Nazwa nr {e.variant}
                      <input
                        type="text"
                        className="validate"
                        style={{
                          backgroundColor: "#cbcdce",
                          borderBottom: "1px solid",
                          marginTop: "5px",
                        }}
                        disabled={true}
                        name={e.variant}
                        minLength="1"
                        value={e.name}
                      />
                    </label>
                  </div>
                </div>
              </div>
            );
          }
        });
      numbers.forEach((e) => {
        if (e === i) {
          name.push(
              featuresGroup == null ? <Name number={e} key={e} handleChangeInput={handleChangeInput} /> : <NameDisabled number={e} key={e} />
          );
        }
      });
    }
    return <>{name}</>;
  };

  useEffect(() => {
    setTimeout(function () {
      setAlert(false);
    }, 4000);
    // eslint-disable-next-line
  }, [alert]);

  return (
    <>
      {loader === true ? (
        <Loader changeColor={"changeColor"} />
      ) : (
        <div className="names">
          {alert === true && (
            <Alert variant="filled" severity="warning">
              Wprowadź co najmniej jedną nazwę produktu i spróbuj ponownie.
            </Alert>
          )}
          <form onSubmit={submit} className="name">
            <p className="title">Alternatywne nazwy produktu</p>
            {inputs(featuresGroup)}
            <div className="btnName">
              {featuresGroup == null ? (
                  <Button
                      className="saveBtn"
                      onClick={() => submit()}
                      variant="contained"
                      size="large"
                      color="primary"
                      startIcon={<SaveIcon />}
                  >
                    Zapisz
                  </Button>
              ) : ""}

            </div>
          </form>
        </div>
      )}
    </>
  );
};

export { FormNames };
